import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../context/appContext';
import { Loading, Media, PaginationContainer } from '../../../components';
import { getResourceType } from '../../../utils/utils';
import Wrapper from './AllMedia_styles';

const AllMedia = () => {
  const {
    mediaFiles: { data: files, pages, currentPage, query },
    changePage,
    handleQueryChange,
    clearQueryAndPage,
    appLoading,
    showAlert,
    uploadMediaFile,
    getMediaFiles,
  } = useAppContext();

  const [openMenuItem, setOpenMenuItem] = useState(null);

  const resourceInputRef = useRef(null);

  const clearInput = () => {
    if (resourceInputRef.current) resourceInputRef.current.value = '';
  };

  const handleResourceChange = e => {
    const input = e.target;

    if (appLoading || !input.files || !input.files[0]) return;

    const mediaFile = input.files[0];
    const name = mediaFile.name;

    if (!getResourceType(name)) {
      return showAlert({
        alertType: 'danger',
        alertText: 'Unsupported file type',
      });
    }

    uploadMediaFile({ file: mediaFile, name });

    clearInput();
  };

  const submitQuery = e => {
    e.preventDefault();

    let newQuery = {};

    Object.keys(query).forEach(id => {
      const input = document.getElementById(id);
      newQuery[input.name] = input.value;
    });

    handleQueryChange('mediaFiles', newQuery);
    setOpenMenuItem(null);
  };

  useEffect(() => {
    getMediaFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentPage]);

  useEffect(() => {
    return () => clearQueryAndPage('mediaFiles');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className="dashboard-main">
      <div className="title-container">
        <h3>Media Files</h3>
        <label className="btn" htmlFor="resource">
          Upload
          <input
            type="file"
            id="resource"
            name="resource"
            accept="image/*,video/*"
            ref={resourceInputRef}
            style={{ display: 'none' }}
            onChange={handleResourceChange}
          />
        </label>
      </div>

      <form className="search-container" onSubmit={submitQuery}>
        <div className="form-row">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input type="text" className="form-input" id="name" name="name" />
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="type">
            Type
          </label>
          <select className="form-select" name="type" id="type">
            <option value="all">All</option>
            <option value="image">Image</option>
            <option value="video">Video</option>
          </select>
        </div>
        <button type="submit" className="btn" disabled={appLoading}>
          Find
        </button>
      </form>

      {appLoading ? (
        <Loading center />
      ) : (
        <>
          <div className="files-container">
            {files?.map(file => (
              <Media
                key={file._id}
                {...file}
                openMenuItem={openMenuItem}
                setOpenMenuItem={setOpenMenuItem}
              />
            ))}
          </div>
          {pages > 1 && (
            <PaginationContainer
              numOfPages={pages}
              currentPage={currentPage}
              handleChange={changePage}
              objectType="mediaFiles"
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default AllMedia;
