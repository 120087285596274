import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 2rem;

  .dzu-dropzone {
    overflow: auto;
    border-radius: var(--borderRadius);
    border: 4px solid var(--primary-400);
    min-height: 160px;

    .dzu-inputLabel {
      color: var(--primary-400);
      font-weight: bold;
      font-size: 1.35rem;
    }

    .dzu-previewImage {
      max-width: 100px;
      object-fit: cover;
    }

    .dzu-previewFileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn {
      margin: 2rem auto;
      text-transform: none;
    }

    * {
      font-family: inherit;
      letter-spacing: var(--letterSpacing);
    }
  }
`;

export default Wrapper;
