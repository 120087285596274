import Wrapper from './PaginationContainer_styles';

const PaginationContainer = ({
  numOfPages,
  currentPage,
  handleChange,
  objectType,
}) => {
  const pages = Array.from({ length: numOfPages }, (_, index) => {
    return index + 1;
  });

  return (
    <Wrapper>
      <div className="btn-container">
        {pages.map(pageNumber => {
          return (
            <button
              key={pageNumber}
              type="button"
              className={
                pageNumber === currentPage ? 'page-btn active' : 'page-btn'
              }
              onClick={() => handleChange(objectType, pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default PaginationContainer;
