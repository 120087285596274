import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useAppContext } from '../../context/appContext';
import { DropzoneUploader, Camera, Loading } from '../../components';
import finishLine from '../../assets/images/finish-line.svg';
import Wrapper from './ThankYou_styles';

const metaTitle = 'Terzo pilastro - Thank You';

const ThankYou = () => {
    const { sendFinalFormFiles, appLoading } = useAppContext();
    const [searchParams] = useSearchParams();

    const storedToken = localStorage.getItem('token');
    const token = searchParams.get('token');
    const userData = JSON.parse(localStorage.getItem('userData') || 'null');

    const isValidToken = !!storedToken && !!userData && token === storedToken;

    const [isTakingPicture, setIsTakingPicture] = useState(false);

    const [cameraFiles, setCameraFiles] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [isUploadError, setIsUploadError] = useState(false);

    const handleSubmit = () => {
        if (uploads.length < 1) {
            toast.error('Si prega di caricare almeno un file');
            return;
        }
        if (isUploadInProgress) {
            toast.error(
                'Attendere fino al completamento del caricamento di tutti i file'
            );
            return;
        }
        if (isUploadError) {
            toast.error(
                'Sembra che ci sia un errore su alcuni dei tuoi file caricati'
            );
            return;
        }
        setCameraFiles([]);
        sendFinalFormFiles({
            images: uploads.map(upload => upload.url),
            userData,
        });
    };

    return (
        <Wrapper className="container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <section>
                {appLoading ? (
                    <Loading center />
                ) : (
                    <>
                        <h3>Grazie per aver compilato il formulario</h3>
                        <p>La sua richiesta sarà elaborata al più presto</p>
                        {isValidToken ? (
                            <>
                                <p>
                                    <b>
                                        La preghiamo ora di caricare i documenti
                                        seguenti:
                                    </b>
                                </p>
                                <div className="list-container">
                                    <ul>
                                        <li>
                                            Copia fronte/retro della carta di
                                            identità oppure del passaporto
                                        </li>
                                        <li>
                                            Copia fronte/retro del permesso di
                                            soggiorno{' '}
                                            <span className="colored">
                                                (non obbligatorio)
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <DropzoneUploader
                                    initialFiles={cameraFiles}
                                    setUploads={setUploads}
                                    setIsUploadInProgress={
                                        setIsUploadInProgress
                                    }
                                    setIsUploadError={setIsUploadError}
                                />
                                <button
                                    type="button"
                                    className="btn center-btn"
                                    onClick={() => setIsTakingPicture(true)}
                                >
                                    Scattare una foto
                                </button>
                                <button
                                    type="button"
                                    className="btn center-btn"
                                    onClick={handleSubmit}
                                    style={{
                                        background: '#1CC691',
                                        borderColor: '#1CC691',
                                    }}
                                >
                                    Inoltra ora
                                </button>
                                {isTakingPicture && (
                                    <Camera
                                        onClose={() =>
                                            setIsTakingPicture(false)
                                        }
                                        onCameraError={error => {
                                            setIsTakingPicture(false);
                                            const errorMessage =
                                                "Si è verificato un errore durante il tentativo di inizializzare la fotocamera. Assicurati di consentire l'accesso alla fotocamera nelle impostazioni del tuo browser";
                                            toast.error(errorMessage);
                                        }}
                                        onTakePhotoAnimationDone={dataUri => {
                                            fetch(dataUri).then(res => {
                                                res.arrayBuffer().then(buf => {
                                                    const file = new File(
                                                        [buf],
                                                        'image_data_url.jpg',
                                                        { type: 'image/jpeg' }
                                                    );
                                                    setCameraFiles([file]);
                                                });
                                            });
                                            setIsTakingPicture(false);
                                        }}
                                        idealResolution={{
                                            width: 1080,
                                            height: 1080,
                                        }}
                                        isSilentMode
                                        isDisplayStartCameraError={false}
                                    />
                                )}
                            </>
                        ) : (
                            <img
                                className="img"
                                src={finishLine}
                                alt="Finish Line"
                            />
                        )}
                    </>
                )}
            </section>
        </Wrapper>
    );
};
export default ThankYou;
