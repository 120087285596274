import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 20;

  .modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 1rem);
    max-width: 350px;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    padding: 2rem 1rem;
    border-radius: var(--borderRadius);
    border: 1px solid var(--grey-100);
    box-shadow: var(--shadow-3);

    .modal-close {
      font-size: 1rem;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--white);
      border: 1px solid var(--grey-100);

      &:hover {
        background-color: var(--grey-50);
      }
    }
  }

  form {
    display: block !important;
  }

  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }

  @media screen and (min-width: 992px) {
    .modal-content {
      max-width: 400px;
      padding: 2rem;
    }
  }
`;

export default Wrapper;
