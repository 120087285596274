import { Link } from 'react-router-dom';
import Wrapper from './PostCard_styles';

const PostCard = ({
  slug,
  title,
  subtitle,
  category,
  posterPath,
  isFeatured,
}) => {
  return (
    <Wrapper className={isFeatured ? 'featured-post' : ''}>
      <Link to={slug}>
        <div className="post-image">
          <img className="img" src={posterPath} alt={title} />
          <span>{category}</span>
        </div>
        <div className="post-content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </Link>
    </Wrapper>
  );
};

export default PostCard;
