import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import Wrapper from './Modal_styles';

const Modal = ({ onClose, children }) => {
  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => document.body.classList.remove('modal-open');
  }, []);

  return (
    <Wrapper>
      <div className="modal-content">
        <button className="modal-close" type="button" onClick={onClose}>
          <MdClose />
        </button>
        {children}
      </div>
    </Wrapper>
  );
};

export default Modal;
