import styled from 'styled-components';

const Wrapper = styled.main`
  color: var(--grey-400);

  section {
    padding: 3rem 0;
  }

  .hero-section {
    .hero-text {
      margin-bottom: 5rem;
    }
    .img {
      margin: 0 auto;
      max-width: 570px;
    }
  }

  .contact {
    .item {
      border-radius: var(--borderRadius);
      box-shadow: var(--shadow-3);

      & > button {
        width: 100%;
      }
      h5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        margin-bottom: 0;
        text-align: left;

        svg {
          min-width: 28px;
          margin-left: 0.5rem;
          font-size: 1.75rem;
        }
      }

      .accordion {
        text-align: center;
        max-height: 0;
        overflow: hidden;

        & > div {
          padding: 2rem;
          padding-top: 1rem;
          opacity: 0;
          transform: translateY(-10px);
          transition: all 0.4s ease-out;
        }

        &.open {
          max-height: 10000px;

          div {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  .phone-us,
  .mail-us,
  .schedule {
    .accordion {
      p {
        letter-spacing: var(--letterSpacing);
        font-weight: bold;
        color: var(--primary-500);
        font-size: 1.25rem;
        margin: 0;
      }

      a {
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.75rem auto;
        text-transform: none;
        max-width: 120px;

        svg {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .hero-section {
      text-align: center;
    }
    p {
      font-size: 1.15rem;
    }
    section {
      padding: 5rem 0;
    }
    .contact .item h5 svg {
      font-size: 2rem;
    }
  }
`;

export default Wrapper;
