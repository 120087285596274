import React, { useReducer, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PUBLIC_POSTS_PER_PAGE } from '../utils/constants';
import reducer from './appReducer';
import {
    BEGIN_REQUEST,
    FINISH_REQUEST,
    REQUEST_ERROR,
    DISPLAY_ALERT,
    CLEAR_ALERT,
    GET_PUBLIC_POSTS_SUCCESS,
    GET_SINGLE_PUBLIC_POST_SUCCESS,
    TOGGLE_DASHBOARD_SIDEBAR,
    CLOSE_DASHBOARD_SIDEBAR,
    LOGOUT_USER,
    LOGIN_USER_SUCCESS,
    CHANGE_PAGE,
    HANDLE_QUERY_CHANGE,
    CLEAR_QUERY_AND_PAGE,
    GET_MEDIA_FILES_SUCCESS,
    GET_POSTS_ADMIN_SUCCESS,
    START_EDIT_POST,
    STOP_EDIT_POST,
} from './actions';

const user = localStorage.getItem('user');
const token = localStorage.getItem('token');

const initialState = {
    user: user ? JSON.parse(user) : null,
    token: token,
    appLoading: false,
    showAlert: false,
    alertType: '',
    alertText: '',
    showDashboardSidebar: false,
    mediaFiles: {
        data: [],
        pages: 1,
        currentPage: 1,
        query: {
            name: '',
            type: 'all',
        },
    },
    posts: {
        categories: [],
        featuredPost: null,
        data: [],
        pages: 1,
        currentPage: 1,
        query: {
            search: '',
            category: 'all',
            language: 'all',
            published: 'all',
            featured: 'all',
        },
    },
    singlePost: null,
    editingPostData: null,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);

    const axiosAuth = axios.create({
        baseURL: '/api/v1',
    });

    axiosAuth.interceptors.request.use(
        config => {
            config.headers.common['Authorization'] = `Bearer ${state.token}`;
            return config;
        },
        err => {
            return Promise.reject(err);
        }
    );

    axiosAuth.interceptors.response.use(
        response => {
            return response;
        },
        err => {
            if (err.response.status === 401) {
                logoutUser();
            }
            return Promise.reject(err);
        }
    );

    const addToLocalStorage = ({ user, token }) => {
        if (user) localStorage.setItem('user', JSON.stringify(user));
        if (token) localStorage.setItem('token', token);
    };
    const clearLocalStorage = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };

    const sendPricingForm = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/pricing`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'La tua richiesta è stata inviata',
                    alertType: 'success',
                },
            });
            navigate('/thank-you');
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const sendFinalForm = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/final-form`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'La tua richiesta è stata inviata',
                    alertType: 'success',
                },
            });
            const token = crypto.randomUUID();
            localStorage.setItem('token', token.toString());
            localStorage.setItem(
                'userData',
                JSON.stringify({ fullName: values.fullName, avs: values.avs })
            );
            navigate(`/final-form/thank-you?token=${token}`);
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const sendFinalFormFiles = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/final-form-files`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'La tua richiesta è stata inviata',
                    alertType: 'success',
                },
            });
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            navigate('/final-form/thank-you');
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const getPosts = async query => {
        const { search, category } = query;

        let url = `/api/v1/posts/public/IT?category=${category}&search=${search}`;

        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axios.get(url);
            const { featuredPost, posts, availableCategories } = data;
            const numOfPages = Math.ceil(posts.length / PUBLIC_POSTS_PER_PAGE);
            dispatch({
                type: GET_PUBLIC_POSTS_SUCCESS,
                payload: {
                    featuredPost,
                    posts,
                    numOfPages,
                    categories: availableCategories,
                },
            });
        } catch (error) {
            console.log(error.response);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const getSinglePost = async slug => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axios.get(`/api/v1/posts/public/IT/${slug}`);
            const { post } = data;
            dispatch({
                type: GET_SINGLE_PUBLIC_POST_SUCCESS,
                payload: { post },
            });
        } catch (error) {
            navigate('/info');
        }
    };

    const showAlert = ({ alertType, alertText }) => {
        dispatch({ type: DISPLAY_ALERT, payload: { alertType, alertText } });
    };

    const toggleDashboardSidebar = () => {
        dispatch({ type: TOGGLE_DASHBOARD_SIDEBAR });
    };

    const closeDashboardSidebar = () => {
        dispatch({ type: CLOSE_DASHBOARD_SIDEBAR });
    };

    const logoutUser = () => {
        dispatch({ type: LOGOUT_USER });
        clearLocalStorage();
    };

    const loginUser = async currentUser => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axios.post(
                `/api/v1/auth/login`,
                currentUser
            );
            const { user, token } = data;
            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: { user, token },
            });
            addToLocalStorage({ user, token });
        } catch (error) {
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const changePage = (objectType, page) => {
        dispatch({ type: CHANGE_PAGE, payload: { objectType, page } });
    };

    const handleQueryChange = (objectType, query) => {
        dispatch({ type: HANDLE_QUERY_CHANGE, payload: { objectType, query } });
    };

    const clearQueryAndPage = objectType => {
        dispatch({ type: CLEAR_QUERY_AND_PAGE, payload: { objectType } });
    };

    const getMediaFiles = async () => {
        const {
            currentPage: page,
            query: { name, type },
        } = state.mediaFiles;

        let url = `/media?page=${page}&name=${name}&type=${type}`;

        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axiosAuth.get(url);
            const { files, numOfPages } = data;
            dispatch({
                type: GET_MEDIA_FILES_SUCCESS,
                payload: { files, numOfPages },
            });
        } catch (error) {
            console.log(error.response);
            logoutUser();
        }
    };

    const uploadMediaFile = async ({ file, name }) => {
        const toastId = toast.info(
            'Media file is being uploaded, please wait...',
            {
                autoClose: false,
            }
        );

        const formData = new FormData();
        formData.append('resource', file);
        try {
            const { data } = await axiosAuth.post(`/media/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const { url } = data;

            await axiosAuth.post('/media', {
                name,
                resourcePath: url,
            });

            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Media file successfully uploaded!',
                    alertType: 'success',
                },
            });

            getMediaFiles();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }

        toast.dismiss(toastId);
    };

    const deleteMediaFile = async mediaId => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axiosAuth.delete(`/media/${mediaId}`);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Media file deleted',
                    alertType: 'success',
                },
            });
            getMediaFiles();
        } catch (error) {
            console.log(error.response);
            logoutUser();
        }
    };

    const getPostsAdmin = async () => {
        const {
            currentPage: page,
            query: { search, category, language, published, featured },
        } = state.posts;

        let url = `/posts?page=${page}&category=${category}&search=${search}&language=${language}&published=${published}&featured=${featured}`;

        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axiosAuth.get(url);
            const { posts, numOfPages, availableCategories } = data;
            dispatch({
                type: GET_POSTS_ADMIN_SUCCESS,
                payload: { posts, numOfPages, categories: availableCategories },
            });
        } catch (error) {
            console.log(error.response);
            logoutUser();
        }
    };

    const createPost = async post => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axiosAuth.post('/posts', post);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Post successfully uploaded!',
                    alertType: 'success',
                },
            });
            navigate('/admin/posts');
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const updatePost = async (postId, alertText, newValues) => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axiosAuth.patch(`/posts/${postId}`, newValues);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText,
                    alertType: 'success',
                },
            });
            if (newValues.title) {
                navigate('/admin/posts');
            } else {
                getPostsAdmin();
            }
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const deletePost = async postId => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axiosAuth.delete(`/posts/${postId}`);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Post deleted',
                    alertType: 'success',
                },
            });
            getPostsAdmin();
        } catch (error) {
            console.log(error.response);
            logoutUser();
        }
    };

    const startEditPost = postId => {
        const post = state.posts.data.find(post => post._id === postId);
        dispatch({ type: START_EDIT_POST, payload: { post } });
        navigate('/admin/posts/post');
    };

    const stopEditPost = () => {
        dispatch({ type: STOP_EDIT_POST });
    };

    useEffect(() => {
        const { showAlert, alertType, alertText } = state;

        if (showAlert) {
            if (alertType === 'success') {
                toast.success(alertText);
            }
            if (alertType === 'danger') {
                toast.error(alertText);
            }
            if (alertType === 'info') {
                toast.info(alertText);
            }

            setTimeout(() => {
                dispatch({ type: CLEAR_ALERT });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showAlert]);

    useEffect(() => {
        if (state.showDashboardSidebar) {
            document.body.classList.add('sidebar-open');
        } else {
            document.body.classList.remove('sidebar-open');
        }
    }, [state.showDashboardSidebar]);

    return (
        <AppContext.Provider
            value={{
                ...state,
                sendPricingForm,
                sendFinalForm,
                sendFinalFormFiles,
                getPosts,
                getSinglePost,
                showAlert,
                toggleDashboardSidebar,
                closeDashboardSidebar,
                logoutUser,
                loginUser,
                changePage,
                handleQueryChange,
                clearQueryAndPage,
                getMediaFiles,
                uploadMediaFile,
                deleteMediaFile,
                getPostsAdmin,
                createPost,
                updatePost,
                deletePost,
                startEditPost,
                stopEditPost,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
