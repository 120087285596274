import styled from 'styled-components';

const Wrapper = styled.main`
  .img {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 5rem;
  }

  section {
    margin: 5rem auto;
    max-width: 770px;
    &.full-width-section {
      max-width: none;
    }
    h3,
    p {
      text-align: center;
    }
    p {
      font-size: 1.25rem;

      & .info-btn {
        margin-left: 0.75rem;
        + span {
          display: block;
          text-align: left;
          font-size: var(--extra-small-text);
          border-radius: var(--borderRadius);
          background-color: var(--primary-400);
          color: var(--white);
          padding: 0.75rem 1rem;
          max-width: 500px;
          margin: 0 auto;
          margin-top: 0.25rem;
        }
      }
    }
    button {
      display: block;
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .form-row {
    margin-bottom: 1rem;

    &.has-info-btn {
      position: relative;

      .form-radio-label {
        padding-right: 3rem;

        span {
          display: block;
          font-size: var(--extra-small-text);
          font-weight: normal;
          padding: 0 1rem;
          margin-top: 1rem;
        }
      }
    }

    &.is-double {
      display: grid;
      column-gap: 1.5rem;
      margin-bottom: 0;

      & > div {
        margin-bottom: 1rem;
      }

      .form-radio-label-img {
        margin-bottom: 0.5rem;
      }
    }

    &.is-small {
      row-gap: 0.5rem;
      margin-bottom: 1.5rem;
    }

    input[type='checkbox'],
    input[type='radio'] {
      display: none;

      &:checked {
        & + label {
          background-color: var(--primary-400);
          color: var(--white);

          &.form-radio-label-img {
            background-color: var(--grey-50);
            border-color: var(--primary-400);
            color: inherit;
          }

          &.has-indicator::after {
            display: block;
          }
        }

        & ~ .info-btn {
          color: var(--white);
        }
      }

      &:disabled {
        & + label {
          &.has-indicator::after {
            border-color: var(--grey-200);
          }
        }
      }
    }

    input[name='solution'] {
      + label {
        opacity: 0.7;
      }

      &:checked + label {
        opacity: 1;
      }
    }

    .form-radio-label {
      cursor: pointer;
      display: block;
      padding: 0.75rem 1rem;
      border-radius: var(--borderRadius);
      background-color: var(--grey-50);
      letter-spacing: var(--letterSpacing);
      font-weight: bold;
      font-size: 1.05rem;
      border: 4px solid transparent;

      &.has-indicator {
        position: relative;
        padding-left: 2.75rem;

        &::after {
          content: '';
          display: none;
          position: absolute;
          left: calc(1rem + 0.3rem);
          top: calc(1rem + 0.1rem);
          width: 5px;
          height: 10px;
          border: solid var(--white);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &.form-radio-label-img {
        padding: 1rem;
        .img {
          margin: 0 auto;
          max-width: 200px;
          margin-bottom: 1rem;
          user-select: none;
          pointer-events: none;
        }
        span,
        small {
          display: block;
          text-align: center;
        }
        small {
          margin: 0 auto;
          margin-top: 0.25rem;
          max-width: 250px;
          font-weight: normal;
        }
      }
    }

    .info-btn {
      position: absolute;
      top: 0.75rem;
      right: 1rem;
      font-size: 1.25rem;

      svg {
        display: block;
      }
    }
  }

  .info-btn {
    display: inline-block;
    margin: 0;
  }

  .form-row.is-small .form-radio-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  section.new-or-existing {
    text-align: center;
  }

  .grid-2 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    column-gap: 1.5rem;
  }

  .default-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;

    &,
    & * {
      cursor: pointer;
    }

    input {
      display: block !important;
      margin: 0 15px 0 5px;
    }
  }

  @media screen and (min-width: 768px) {
    .form-row.is-double {
      grid-template-columns: 1fr 1fr;
    }
    .form-row.is-small {
      min-height: 80px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
    .grid-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export default Wrapper;
