import styled from 'styled-components';

const Wrapper = styled.section`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn-container {
    text-align: center;
    border-radius: var(--borderRadius);
  }
  .page-btn {
    background: transparent;
    border: none;
    width: 45px;
    height: 32px;
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--primary-500);
    transition: var(--transition);
    border-radius: var(--borderRadius);
    cursor: pointer;
  }
  .active {
    background: var(--primary-500);
    color: var(--white);
  }
`;
export default Wrapper;
