import styled from 'styled-components';

const Wrapper = styled.main`
  textarea {
    height: 390px;
  }

  .markdown-preview {
    margin-top: 2rem;

    & > div {
      height: 90vh;
      background: var(--backgroundColor);
      border: 1px solid var(--grey-200);
      overflow: auto;
      padding: 2rem 0.5rem;
    }
  }

  .form-select[name='category'] {
    text-transform: capitalize;
  }

  .form-row.has-button .btn {
    border-radius: var(--borderRadius);
    padding: 0.5rem;
    font-size: 1.3rem;
    display: flex;
  }
`;

export default Wrapper;
