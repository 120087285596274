import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/terzopilastro.png';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { RiGlobalLine } from 'react-icons/ri';
import { BiMenuAltRight } from 'react-icons/bi';
import { IoCloseSharp } from 'react-icons/io5';
import { DE_DOMAIN } from '../../utils/constants';
import Wrapper from './NavBar_styles';

const NavBar = () => {
  const { pathname } = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const handleOutsideSidebarClick = e => {
    if (e.target.classList.contains('mobile-sidebar')) {
      closeSidebar();
    }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    window.addEventListener('resize', closeSidebar);

    return () => window.removeEventListener('resize', closeSidebar);
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <ul className="desktop-menu">
          <li className="link-item">
            <Link to="/">Home</Link>
          </li>
          <li className="link-item">
            <Link to="/pricing">Calcola</Link>
          </li>
          <li className="link-item">
            <Link to="/info">Info</Link>
          </li>
          <li
            className={
              isLanguageMenuOpen
                ? 'languages-list mobile-element is-open'
                : 'languages-list mobile-element'
            }
          >
            <button
              type="button"
              onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
            >
              <RiGlobalLine />
              <span>IT</span>
              {isLanguageMenuOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <ul>
              <li>
                <Link to="#">IT</Link>
              </li>
              <li>
                <a href={`${DE_DOMAIN}${pathname}`}>DE</a>
              </li>
              <li>
                <Link to="#">FR</Link>
              </li>
            </ul>
          </li>
        </ul>
        <div className="logo-container">
          <img className="img" src={logo} alt="Terzopilastro" />
        </div>
        <ul className="desktop-menu">
          <li className="link-item">
            <Link to="/contact">Contatto</Link>
          </li>
          <li className="link-item">
            <Link to="/faq">FAQ</Link>
          </li>
          <li
            className={
              isLanguageMenuOpen ? 'languages-list is-open' : 'languages-list'
            }
          >
            <button
              type="button"
              onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
            >
              <RiGlobalLine className="globe-icon" />
              <span>IT</span>
              {isLanguageMenuOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <ul>
              <li>
                <Link to="#">IT</Link>
              </li>
              <li>
                <a href={`${DE_DOMAIN}${pathname}`}>DE</a>
              </li>
              <li>
                <Link to="#">FR</Link>
              </li>
            </ul>
          </li>
          <li className="menu-btn mobile-element">
            <button
              type="button"
              onClick={() => setIsSidebarOpen(true)}
              aria-label="Open Menu"
            >
              <BiMenuAltRight />
            </button>
          </li>
        </ul>
      </div>

      <div
        className={isSidebarOpen ? 'mobile-sidebar is-open' : 'mobile-sidebar'}
        onClick={handleOutsideSidebarClick}
      >
        <ul>
          <li className="close-sidebar">
            <button
              type="button"
              onClick={closeSidebar}
              aria-label="Close Menu"
            >
              <IoCloseSharp />
            </button>
          </li>
          <li>
            <Link to="/" onClick={closeSidebar}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/pricing" onClick={closeSidebar}>
              Calcola
            </Link>
          </li>

          <li>
            <Link to="/info" onClick={closeSidebar}>
              Info
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeSidebar}>
              Contatto
            </Link>
          </li>
          <li>
            <Link to="/faq" onClick={closeSidebar}>
              FAQ
            </Link>
          </li>
          <li className="logo-container">
            <hr />
            <div>
              <small>© {new Date().getFullYear()} elefin</small>
            </div>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default NavBar;
