export const BEGIN_REQUEST = 'BEGIN_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
export const REQUEST_ERROR = 'REQUEST_ERROR';

export const DISPLAY_ALERT = 'DISPLAY_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const GET_PUBLIC_POSTS_SUCCESS = 'GET_PUBLIC_POSTS_SUCCESS';
export const GET_SINGLE_PUBLIC_POST_SUCCESS = 'GET_SINGLE_PUBLIC_POST_SUCCESS';

export const TOGGLE_DASHBOARD_SIDEBAR = 'TOGGLE_DASHBOARD_SIDEBAR';
export const CLOSE_DASHBOARD_SIDEBAR = 'CLOSE_DASHBOARD_SIDEBAR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const HANDLE_QUERY_CHANGE = 'HANDLE_QUERY_CHANGE';

export const CLEAR_QUERY_AND_PAGE = 'CLEAR_QUERY_AND_PAGE';

export const GET_MEDIA_FILES_SUCCESS = 'GET_MEDIA_FILES_SUCCESS';

export const GET_POSTS_ADMIN_SUCCESS = 'GET_POSTS_ADMIN_SUCCESS';

export const START_EDIT_POST = 'START_EDIT_POST';
export const STOP_EDIT_POST = 'STOP_EDIT_POST';
