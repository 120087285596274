import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useAppContext } from '../../context/appContext';
import { Loading } from '../../components';
import { CgCloseO } from 'react-icons/cg';
import Wrapper from './FinalForm_styles';

const metaTitle = 'Terzo pilastro - Formulario conclusivo';

const emptyHealthProblem = { illness: '', date: '' };
const emptySurgeryContent = {
    illness: '',
    date: '',
    doctorNameAndAddress: '',
    isConcluded: null,
};
const emptyMedicationContent = {
    name: '',
    date: '',
    doctorNameAndAddress: '',
};

const initialForm = {
    fullName: '',
    avs: '',
    taxCode: '',
    civilStatus: '',
    address: '',
    height: '',
    weight: '',
    declined: {
        value: null,
        content: '',
    },
    smoker: {
        value: null,
        content: '',
    },
    atRisk: {
        value: null,
        content: '',
    },
    abroad: {
        value: null,
        content: '',
    },
    drugs: {
        value: null,
        content: '',
    },
    hiv: {
        value: null,
        content: '',
    },
    healthProblem: {
        value: null,
        content: [emptyHealthProblem],
    },
    surgery: {
        value: null,
        content: [emptySurgeryContent],
    },
    noWork: {
        value: null,
        content: [emptySurgeryContent],
    },
    medications: {
        value: null,
        content: [emptyMedicationContent],
    },
    doctorNameAndAddress: '',
    remarks: '',
};

const notRequiredFields = ['taxCode', 'doctorNameAndAddress', 'remarks'];

const FinalForm = () => {
    const { sendFinalForm, appLoading } = useAppContext();

    const [form, setForm] = useState(initialForm);

    const handleFormChange = (name, value, objKey = null) =>
        setForm(prev => {
            if (objKey) {
                return {
                    ...prev,
                    [objKey]: {
                        ...prev[objKey],
                        [name]: value,
                        ...(name === 'value' && value === false
                            ? {
                                  content: Array.isArray(
                                      initialForm[objKey].content
                                  )
                                      ? [...initialForm[objKey].content]
                                      : '',
                              }
                            : {}),
                    },
                };
            }
            return { ...prev, [name]: value };
        });

    const handleToggleNestedContent = (name, indexToRemove = null) =>
        setForm(prev => ({
            ...prev,
            [name]: {
                ...prev[name],
                content:
                    indexToRemove === null
                        ? [
                              ...prev[name].content,
                              {
                                  id: crypto.randomUUID(),
                                  ...initialForm[name].content[0],
                              },
                          ]
                        : prev[name].content.filter(
                              (_, i) => i !== indexToRemove
                          ),
            },
        }));

    const handleNestedContentChange = (name, value, objKey, index) =>
        setForm(prev => ({
            ...prev,
            [objKey]: {
                ...prev[objKey],
                content: prev[objKey].content.map((content, i) => {
                    if (i === index) {
                        return {
                            ...content,
                            [name]: value,
                        };
                    }
                    return content;
                }),
            },
        }));

    const handleSubmit = () => {
        const hasMissingFields = Object.entries(form).some(([key, value]) => {
            if (notRequiredFields.includes(key)) return false;
            if (typeof value === 'string') {
                return value === '';
            }
            if (value.value === true) {
                if (typeof value.content === 'string') {
                    return value.content === '';
                }
                return value.content.some(
                    content =>
                        Object.values(content).includes('') ||
                        Object.values(content).includes(null)
                );
            }
            return value.value === null;
        });

        if (hasMissingFields) {
            toast.error('Si prega di compilare tutti i campi');
            return;
        }

        sendFinalForm(form);
    };

    const formContent = (
        <>
            <section className="full-width-section">
                <h1>Formulario conclusivo per la proposta</h1>
                <div style={{ maxWidth: 770, margin: '0 auto' }}>
                    <p className="hero-text">
                        Caro cliente, questo formulario è l'ultimo passaggio che
                        precede la firma elettronica ed'è necessario per
                        raccogliere tutte le informazioni utili alla stipula del
                        contratto.
                    </p>
                    <p className="hero-text">
                        I dati raccolti in questo formulario sono confidenziali
                        e non vengono divulgati a terze parti (eccetto il
                        partner con cui si ha deciso di stipulare un contratto)
                    </p>
                </div>
            </section>
            <section>
                <div className="form-row is-double">
                    <InputRow
                        label="Nome e cognome"
                        name="fullName"
                        onChange={e =>
                            handleFormChange('fullName', e.target.value)
                        }
                    />
                    <InputRow
                        label="Numero AVS"
                        name="avs"
                        onChange={e => handleFormChange('avs', e.target.value)}
                    />
                </div>
                <div className="form-row is-double">
                    <InputRow
                        label="Codice fiscale (se residente all'estero)"
                        name="taxCode"
                        onChange={e =>
                            handleFormChange('taxCode', e.target.value)
                        }
                    />
                    <div>
                        <label className="form-label" htmlFor="civilStatus">
                            Stato civile
                        </label>
                        <select
                            className="form-select"
                            id="civilStatus"
                            onChange={e =>
                                handleFormChange('civilStatus', e.target.value)
                            }
                            value={form.civilStatus}
                        >
                            <option value="" disabled>
                                Seleziona
                            </option>
                            <option value="Celibe / Nubile">
                                Celibe / Nubile
                            </option>
                            <option value="Coniugato/a">Coniugato/a</option>
                            <option value="Separato/a">Separato/a</option>
                            <option value="Vedovo/a">Vedovo/a</option>
                        </select>
                    </div>
                </div>
                <InputRow
                    className="form-row"
                    label="Indirizzo di domicilio completo"
                    name="address"
                    placeholder="Via, num. civico e comune di residenza"
                    onChange={e => handleFormChange('address', e.target.value)}
                />
            </section>
            <section>
                <div className="form-row is-double">
                    <InputRow
                        label="Altezza"
                        name="height"
                        placeholder="175 cm"
                        maxLength={3}
                        value={form.height}
                        onChange={e => {
                            if (/\D/g.test(e.target.value)) return;
                            handleFormChange('height', e.target.value);
                        }}
                    />
                    <InputRow
                        label="Peso"
                        name="weight"
                        placeholder="75 kg"
                        maxLength={3}
                        value={form.weight}
                        onChange={e => {
                            if (/\D/g.test(e.target.value)) return;
                            handleFormChange('weight', e.target.value);
                        }}
                    />
                </div>
            </section>
            <section>
                <BooleanRadioOptions
                    title="Ci sono state richieste di assicurazione sulla vita di altre società che le sono state rifiutate o accettate parzialmente?"
                    option="declined"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'declined')
                    }
                />
                {form.declined.value && (
                    <InputRow
                        className="form-row"
                        label="Società assicurativa e motivo"
                        name="declinedContent"
                        placeholder="Nome della società assicurativa e il motivo del rifiuto / accettazione parziale"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'declined'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Negli ultimi 12 mesi ha fumato sigarette o prodotti del tabacco?"
                    option="smoker"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'smoker')
                    }
                />
                {form.smoker.value && (
                    <InputRow
                        className="form-row"
                        label="Prodotto e quantità"
                        name="smokerContent"
                        placeholder="Quale prodotto? Quantità giornaliera?"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'smoker'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="È esposto a particolari rischi sul posto di lavoro o nel tempo libero?"
                    option="atRisk"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'atRisk')
                    }
                />
                {form.atRisk.value && (
                    <InputRow
                        className="form-row"
                        label="Rischi a cui è esposto"
                        name="atRiskContent"
                        placeholder="Quali sono i particolari rischi a cui è esposto?"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'atRisk'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Nei prossimi 24 mesi è previsto un soggiorno all'estero di oltre 6 mesi?"
                    option="abroad"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'abroad')
                    }
                />
                {form.abroad.value && (
                    <InputRow
                        className="form-row"
                        label="Dove, motivo e durata"
                        name="abroadContent"
                        placeholder="Dove, per quale motivo e durata del soggiorno"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'abroad'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Negli ultimi 5 anni ha fatto uso di sostanze stupefacenti o droghe di qualsiasi genere?"
                    option="drugs"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'drugs')
                    }
                />
                {form.drugs.value && (
                    <InputRow
                        className="form-row"
                        label="Sostanze e quantità"
                        name="drugsContent"
                        placeholder="Quali, per quanto tempo e quantità settimanale"
                        onChange={e =>
                            handleFormChange('content', e.target.value, 'drugs')
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Le è stata diagnosticata un'infezione da HIV?"
                    option="hiv"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'hiv')
                    }
                />
                {form.hiv.value && (
                    <InputRow
                        className="form-row"
                        label="Da quando"
                        name="hivContent"
                        placeholder="A partire da quale data?"
                        onChange={e =>
                            handleFormChange('content', e.target.value, 'hiv')
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Soffre di disturbi della salute oppure infermità congenite dalla nascita?"
                    option="healthProblem"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'healthProblem')
                    }
                />
                {form.healthProblem.value && (
                    <>
                        {form.healthProblem.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Tipo"
                                        name={`healthProblemIllness${i}`}
                                        placeholder="Quale tipo di disturbo?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Da quando"
                                        name={`healthProblemDate${i}`}
                                        placeholder="A partire da quando?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    />
                                </div>
                            </Fragment>
                        ))}
                        {form.healthProblem.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('healthProblem')
                                }
                            >
                                Aggiungi ulteriore disturbo / infermità
                                congenita
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Ha avuto malattie o infortuni, oppure ha effettuato operazioni negli ultimi 10 anni? È previsto in futuro un accertamento medico in seguito a un disturbo della salute?"
                    option="surgery"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'surgery')
                    }
                />
                {form.surgery.value && (
                    <>
                        {form.surgery.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'surgery',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Tipo"
                                        name={`surgeryIllness${i}`}
                                        placeholder="Indicare il tipo di malattia o infortunio"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'surgery',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Data"
                                        name={`surgeryDate${i}`}
                                        placeholder="A partire da quando e fino a quale data?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'surgery',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Nome e indirizzo del medico"
                                    name={`surgeryDoctorAndAddress${i}`}
                                    placeholder="Nome e indirizzo del medico curante"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'surgery',
                                            i
                                        )
                                    }
                                />
                                <div className="form-row">
                                    <span className="block-center">
                                        Il trattamento è concluso ed'è guarito
                                        senza conseguenze?
                                    </span>
                                    <div className="default-radio-container">
                                        <label>
                                            <input
                                                type="radio"
                                                name={`surgeryConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        e.target.checked,
                                                        'surgery',
                                                        i
                                                    )
                                                }
                                            />
                                            Si
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name={`surgeryConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        !e.target.checked,
                                                        'surgery',
                                                        i
                                                    )
                                                }
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {form.surgery.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('surgery')
                                }
                            >
                                Aggiungi ulteriore malattia / infortunio /
                                operazione
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Negli ultimi 5 anni, è stato inabile al lavoro per più di 14 giorni consecutivi?"
                    option="noWork"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'noWork')
                    }
                />
                {form.noWork.value && (
                    <>
                        {form.noWork.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'noWork',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Tipo"
                                        name={`noWorkIllness${i}`}
                                        placeholder="Indicare il tipo di malattia o infortunio"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'noWork',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Data"
                                        name={`noWorkDate${i}`}
                                        placeholder="A partire da quando e fino a quale data?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'noWork',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Nome e indirizzo del medico"
                                    name={`noWorkDoctorAndAddress${i}`}
                                    placeholder="Nome e indirizzo del medico curante"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'noWork',
                                            i
                                        )
                                    }
                                />
                                <div className="form-row">
                                    <span className="block-center">
                                        Il trattamento è concluso ed'è guarito
                                        senza conseguenze?
                                    </span>
                                    <div className="default-radio-container">
                                        <label>
                                            <input
                                                type="radio"
                                                name={`noWorkConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        e.target.checked,
                                                        'noWork',
                                                        i
                                                    )
                                                }
                                            />
                                            Si
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name={`noWorkConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        !e.target.checked,
                                                        'noWork',
                                                        i
                                                    )
                                                }
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {form.noWork.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('noWork')
                                }
                            >
                                Aggiungi ulteriore malattia / infortunio /
                                operazione
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Negli ultimi 3 anni le sono stati prescritti farmaci per oltre 14 giorni?"
                    option="medications"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'medications')
                    }
                />
                {form.medications.value && (
                    <>
                        {form.medications.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'medications',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Farmaco"
                                        name={`medicationName${i}`}
                                        placeholder="Nome del farmaco assunto"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'name',
                                                e.target.value,
                                                'medications',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Data"
                                        name={`medicationDate${i}`}
                                        placeholder="A partire da quando e fino a quale data?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'medications',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Nome e indirizzo del medico"
                                    name={`medicationDoctorAndAddress${i}`}
                                    placeholder="Nome e indirizzo del medico curante"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'medications',
                                            i
                                        )
                                    }
                                />
                            </Fragment>
                        ))}
                        {form.medications.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('medications')
                                }
                            >
                                Aggiungi ulteriore farmaco
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <p>Quale medico è meglio informato sul suo stato di salute?</p>
                <InputRow
                    className="form-row"
                    label="Nome e indirizzo del medico"
                    placeholder="Indicare il nome completo e l'indirizzo del medico"
                    name="doctorNameAndAddress"
                    onChange={e =>
                        handleFormChange('doctorNameAndAddress', e.target.value)
                    }
                />
            </section>
            <section>
                <div className="form-row">
                    <label className="form-label" htmlFor="remarks">
                        Osservazioni
                    </label>
                    <textarea
                        className="form-textarea"
                        name="remarks"
                        id="remarks"
                        rows="5"
                        onChange={e =>
                            handleFormChange('remarks', e.target.value)
                        }
                    ></textarea>
                </div>
                <button
                    type="button"
                    className="btn"
                    onClick={handleSubmit}
                    style={{
                        background: '#1CC691',
                        borderColor: '#1CC691',
                    }}
                >
                    Inoltra ora
                </button>
            </section>
        </>
    );

    return (
        <Wrapper>
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <form className="container">
                {appLoading ? (
                    <section className="full-width-section">
                        <Loading center />
                    </section>
                ) : (
                    formContent
                )}
            </form>
        </Wrapper>
    );
};

const InputRow = ({
    label,
    name,
    type,
    placeholder,
    onChange,
    value,
    maxLength,
    className,
}) => (
    <div className={className}>
        <label className="form-label" htmlFor={name}>
            {label}
        </label>
        <input
            type={type || 'text'}
            className="form-input"
            id={name}
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    </div>
);

const RadioOption = ({ label, id, name, onChange }) => (
    <>
        <input
            type="radio"
            id={id}
            name={name}
            onChange={e => onChange(e.target.checked)}
        />
        <label className="form-radio-label" htmlFor={id}>
            {label}
        </label>
    </>
);

const BooleanRadioOptions = ({ title, option, onChange }) => (
    <>
        <p>{title}</p>
        <div className="form-row is-double is-small">
            <RadioOption
                id={`is-${option}`}
                name={`is-${option}`}
                label="Si"
                onChange={isChecked => onChange(isChecked)}
            />
            <RadioOption
                id={`not-${option}`}
                name={`is-${option}`}
                label="No"
                onChange={isChecked => onChange(!isChecked)}
            />
        </div>
    </>
);

export default FinalForm;
