import styled from 'styled-components';

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  padding: 0 1.5rem;
  background-color: var(--white);
  border-bottom: 2px solid var(--grey-100);

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 1.35rem;
    padding: 0;
  }

  .toggle-btn {
    color: var(--primary-500);
  }

  .logout-btn {
    color: var(--red-dark);
  }

  /* .logo-link {
    width: 120px;
  } */

  @media screen and (min-width: 992px) {
    /* .logo-link {
      display: none;
    } */
  }
`;

export default Wrapper;
