export const IT_DOMAIN = 'https://www.terzopilastro.info';
export const DE_DOMAIN = 'https://www.3-säule.info';

export const faqItems = [
  {
    id: 1,
    title: 'Quanto tempo serve per compilare il modulo?',
    html: '<p>Il tempo stimato per la compilazione del modulo è di ca. 5-10 minuti.</p>',
  },
  {
    id: 2,
    title: 'La mia richiesta online è vincolante?',
    html: '<p>No, una volta compilato il modulo riceverete entro 48h una o più proposte non vincolanti.</p>',
  },
  {
    id: 3,
    title: 'Ho già un terzo pilastro, posso chiedere una valutazione?',
    html: '<p>Certo. Alla fine del modulo, nel campo “osservazioni”, potete indicare che avete già un contratto attivo. In seguito vi verrà richiesta una copia della polizza per fare una valutazione / confronto.</p>',
  },
  {
    id: 4,
    title: 'Sono un frontaliere, posso stipulare un terzo pilastro?',
    html: "<p>Si, anche per chi risiede all'estero è possibile stipulare un terzo pilastro.<br/>È comunque necessario un rapporto lavorativo in Svizzera (permesso G).</p>",
  },
  {
    id: 5,
    title: 'La ricerca ha un costo? Come si finanzia terzopilastro.info?',
    html: "<p>Il servizio di terzopilastro.info è gratuito per l'utente, finanziato direttamente dai partner.</p>",
  },
];

export const postLanguages = ['IT', 'DE', 'FR'];

export const PUBLIC_POSTS_PER_PAGE = 3;
