import { Route, Routes, Outlet, useLocation, Navigate } from 'react-router-dom';
import {
    Home,
    Pricing,
    ThankYou,
    Contact,
    Faq,
    Login,
    BlogPosts,
    SinglePost,
    FinalForm,
    FinalFormThankYou,
} from './pages';
import { AllPosts, AllMedia, Post } from './pages/Admin';
import {
    NavBar,
    Footer,
    AdminSidebar,
    DashboardNavbar,
    AdminProtectedRoute,
} from './components';

const LandingPageLayout = () => {
    const { pathname } = useLocation();

    return (
        <>
            <NavBar />
            <Outlet />
            {pathname !== '/pricing' && <Footer />}
        </>
    );
};

const AdminPageLayout = () => {
    const styles = {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
    };

    return (
        <div style={styles}>
            <AdminSidebar />
            <div>
                <DashboardNavbar />
                <Outlet />
            </div>
        </div>
    );
};

const App = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route
                path="/admin"
                element={
                    <AdminProtectedRoute>
                        <AdminPageLayout />
                    </AdminProtectedRoute>
                }
            >
                <Route index element={<Navigate to="/admin/posts" />} />
                <Route path="posts">
                    <Route index element={<AllPosts />} />
                    <Route path="post" element={<Post />} />
                </Route>
                <Route path="media" element={<AllMedia />} />
                <Route path="*" element={<Navigate to="/admin/posts" />} />
            </Route>
            <Route path="/" element={<LandingPageLayout />}>
                <Route index element={<Home />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="thank-you" element={<ThankYou />} />
                <Route path="contact" element={<Contact />} />
                <Route path="faq" element={<Faq />} />
                <Route path="info">
                    <Route index element={<BlogPosts />} />
                    <Route path=":slug" element={<SinglePost />} />
                </Route>
                <Route path="final-form">
                    <Route index element={<FinalForm />} />
                    <Route path="thank-you" element={<FinalFormThankYou />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
};

export default App;
