import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { useAppContext } from '../../context/appContext';
import Wrapper from './DropzoneUploader_styles';
import { useEffect } from 'react';

const MAX_FILES = 5;
const uploadingStatuses = [
    'uploading',
    'preparing',
    'getting_upload_params',
    'headers_received',
];
const errorStatuses = [
    'rejected_file_type',
    'rejected_max_files',
    'error_file_size',
    'error_validation',
    'error_upload_params',
    'exception_upload',
    'aborted',
    'error_upload',
];

const DropzoneUploader = ({
    initialFiles = [],
    setUploads,
    setIsUploadInProgress,
    setIsUploadError,
}) => {
    const { showAlert } = useAppContext();

    const getUploadParams = () => {
        return { url: `${window.location.origin}/api/v1/contact/uploads` };
    };

    const handleChangeStatus = (currentFile, status, allFiles) => {
        const allFilesStatuses = allFiles.map(({ meta }) => meta.status);
        setIsUploadInProgress(
            uploadingStatuses.some(status => allFilesStatuses.includes(status))
        );
        setIsUploadError(
            errorStatuses.some(status => allFilesStatuses.includes(status))
        );

        let alertText = '';
        if (status === 'rejected_file_type') {
            alertText =
                'Tipo di file non valido. Sono ammessi solo immagini in formato PNG o JPEG';
        }
        if (status === 'rejected_max_files') {
            alertText = `Max ${MAX_FILES} file consentiti`;
        }
        if (status === 'error_file_size') {
            alertText = `La dimensione del file non può superare i 5MB`;
        }
        if (status === 'exception_upload' || status === 'error_upload') {
            alertText =
                'Errore durante il caricamento del file. Riprova più tardi';
        }
        if (status === 'done' || status === 'removed') {
            const uploadedFiles = allFiles
                .filter(({ meta }) => meta.status === 'done')
                .map(({ xhr }) => JSON.parse(xhr.response));

            setUploads(uploadedFiles);
        }

        if (alertText) {
            showAlert({
                alertType: 'danger',
                alertText,
            });
        }
    };

    useEffect(() => {
        return () => {
            setIsUploadInProgress(false);
            setIsUploadError(false);
            setUploads([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Dropzone
                initialFiles={initialFiles}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={MAX_FILES}
                maxSizeBytes={5 * 1024 * 1024}
                inputContent={'Carica file'}
                inputWithFilesContent={'Carica di più'}
                SubmitButtonComponent={null}
                classNames={{ inputLabelWithFiles: 'btn' }}
                accept="image/png,image/jpeg"
            />
        </Wrapper>
    );
};

export default DropzoneUploader;
