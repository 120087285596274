import { useEffect, useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { CgCloseO } from 'react-icons/cg';
import { MdFlipCameraIos } from 'react-icons/md';
import Wrapper from './Camera_styles';

const CameraComponent = ({
    onClose,
    idealFacingMode = 'environment',
    ...cameraProps
}) => {
    const [facingMode, setFacingMode] = useState(idealFacingMode);

    const flipCamera = () =>
        setFacingMode(facingMode === 'environment' ? 'user' : 'environment');

    useEffect(() => {
        const iubendaBtn = document.querySelector('.iubenda-tp-btn');
        if (!iubendaBtn) return;
        iubendaBtn.classList.add('iubenda-tp-btn-hidden');
        return () => {
            if (!iubendaBtn) return;
            iubendaBtn.classList.remove('iubenda-tp-btn-hidden');
        };
    }, []);

    return (
        <Wrapper>
            <button type="button" className="close-btn" onClick={onClose}>
                <CgCloseO />
            </button>
            <button type="button" className="flip-btn" onClick={flipCamera}>
                <MdFlipCameraIos />
            </button>
            <Camera
                imageType="jpg"
                {...cameraProps}
                idealFacingMode={facingMode}
            />
        </Wrapper>
    );
};
export default CameraComponent;
