import { HiMenuAlt1 } from 'react-icons/hi';
import { MdPowerSettingsNew } from 'react-icons/md';
import { useAppContext } from '../../../context/appContext';
import Wrapper from './DashboardNavbar_styles';

const DashboardNavbar = () => {
  const { toggleDashboardSidebar, logoutUser } = useAppContext();

  return (
    <Wrapper>
      <button
        type="button"
        className="toggle-btn"
        onClick={toggleDashboardSidebar}
      >
        <HiMenuAlt1 />
      </button>
      <button type="button" className="logout-btn" onClick={logoutUser}>
        <MdPowerSettingsNew />
      </button>
    </Wrapper>
  );
};

export default DashboardNavbar;
