import styled from 'styled-components';

const Wrapper = styled.article`
  position: relative;
  border-radius: var(--borderRadius);
  width: 100%;
  max-width: 335px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  line-height: 1;
  font-weight: bold;
  font-size: var(--small-text);
  box-shadow: var(--shadow-1);

  .img {
    height: 158px;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .poster-container {
    position: relative;

    .poster-footer {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.5rem;
      background-color: rgba(0, 0, 0, 0.2);

      .status {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: var(--grey-600);
        border: 2px solid var(--white);

        &.is-published {
          background-color: var(--green);
        }
      }

      .feature {
        font-size: 1.2rem;
        color: transparent;

        &.is-featured {
          color: var(--gold);
        }
      }
    }
  }

  .post-title {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid var(--grey-100);
  }

  .post-info {
    color: var(--primary-500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    font-size: var(--extra-small-text);

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.25rem;

      svg {
        font-size: 1rem;
        margin-right: 0.25rem;
      }
    }

    .category {
      text-transform: capitalize;
    }
  }
`;

export default Wrapper;
