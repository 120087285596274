import styled from 'styled-components';

const Wrapper = styled.aside`
  .sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80vw;
    max-width: 220px;
    padding: 1rem 0.5rem;
    background-color: var(--grey-50);
    color: var(--grey-700);
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: translateX(0);
    }
  }

  ul {
    li {
      margin-bottom: 0.25rem;
    }

    li.list-header,
    li a {
      padding: 0.25rem 0.5rem;
      color: inherit;
    }
    li.list-header {
      font-size: var(--extra-small-text);
      letter-spacing: var(--letterSpacing);
      color: var(--grey-500);
    }

    li a {
      display: flex;
      align-items: center;
      font-size: var(--small-text);
      border-radius: var(--borderRadius);
      text-decoration: none;

      svg {
        font-size: 1.25rem;
        margin-right: 0.75rem;
      }

      &:hover {
        background-color: var(--grey-100);
      }

      &.active {
        background-color: var(--primary-500);
        color: var(--grey-50);
      }
    }
  }

  .overlay {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.6);
  }

  @media screen and (min-width: 992px) {
    width: 80vw;
    max-width: 220px;

    &.active {
      margin-left: -250px;
    }

    .overlay {
      display: none;
    }

    .sidebar {
      transform: none;
    }

    &,
    .sidebar {
      transition: margin 0.3s ease-in-out;

      &.active {
        margin-left: -250px;
      }
    }
  }
`;

export default Wrapper;
