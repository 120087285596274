import styled from 'styled-components';

const Wrapper = styled.main`
  section {
    padding: 3rem 0;
  }

  .hero-section {
    padding-bottom: 3rem;
    .hero-text,
    .form-row {
      max-width: 600px;
      margin: 0 auto;
    }
    .hero-text {
      margin-bottom: 2rem;
      color: var(--grey-400);
    }
    .img {
      margin: 0 auto;
      max-width: 570px;
    }
  }

  select[name='category'] {
    text-transform: capitalize;
  }

  .loading {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .posts-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    column-gap: 1rem;
  }

  .btn-container {
    text-align: center;
    padding-top: 0;
    .btn {
      text-transform: none;
    }
  }

  @media screen and (min-width: 543px) {
    .posts-container {
      grid-template-columns: 1fr 1fr;

      .featured-post {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .hero-section {
      text-align: center;
    }
    p {
      font-size: 1.15rem;
    }
    section {
      padding: 5rem 0;
    }
  }

  @media screen and (min-width: 992px) {
    .posts-container {
      grid-template-columns: 1fr 1fr 1fr;

      .featured-post {
        grid-column-end: 4;
      }
    }
  }
`;

export default Wrapper;
