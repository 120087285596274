import styled from 'styled-components';

const Wrapper = styled.nav`
  padding: 1rem 0;
  font-size: 1.25rem;
  color: var(--grey-400);

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    display: flex;
    align-items: center;
  }

  li a {
    display: inline-block;
  }

  .desktop-menu {
    & > li:not(.mobile-element) {
      display: none;
    }

    .link-item {
      a {
        padding: 1rem;
      }
    }

    &:first-of-type {
      .link-item:first-of-type a {
        padding-left: 0;
      }
    }
  }

  .menu-btn {
    font-size: 2.25rem;
  }

  .mobile-sidebar {
    color: var(--textColor);
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.6);
    transform: translateX(100%);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;

    &.is-open {
      opacity: 1;
      transform: translateX(0);
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 80%;
      max-width: 400px;
      height: 100%;
      background-color: var(--grey-50);
      padding: 0.75rem 1.5rem;
      font-size: 1.5rem;
      overflow: auto;

      .close-sidebar {
        button {
          margin: 0 0 0 auto;
          font-size: 2.25rem;
          padding: 1rem 0.25rem;
        }
      }

      li a {
        padding: 0.75rem 0;
      }

      li.logo-container {
        margin: auto 0 0 0;
        text-align: center;
        hr {
          margin: 1.5rem 0;
        }
        & > div {
          display: inline-flex;
          align-items: center;
          .img {
            display: inline-block;
            width: 30px;
            margin-right: 0.5rem;
          }
          small {
            color: var(--grey-400);
          }
        }
      }
    }
  }

  .languages-list {
    position: relative;
    font-size: 1rem;

    button {
      svg:first-of-type {
        font-size: 1.25rem;
      }

      span {
        font-weight: 600;
        padding-left: 0.25rem;
        padding-right: 0.15rem;
      }
    }
    ul {
      display: none;
      position: absolute;
      left: 0;
      top: calc(100% + 0.5rem);
      width: 100%;
      text-align: center;
      padding: 0.25rem;
      background-color: var(--white);
      border-radius: var(--borderRadius);
      border: 1px solid var(--grey-100);
      box-shadow: var(--shadow-3);
      z-index: 2;

      li a {
        display: block;
        padding: 0.25rem;
      }
    }

    &.is-open {
      button {
        color: var(--primary-500);
      }
      ul {
        display: block;
      }
    }
  }

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;

    & > ul {
      display: flex;
      align-items: center;

      &:last-of-type {
        justify-content: flex-end;
      }
    }

    .logo-container {
      max-width: 200px;
      padding: 0 0.5rem;
    }
  }

  @media screen and (min-width: 992px) {
    .mobile-sidebar {
      display: none;
    }

    .container .logo-container {
      max-width: 250px;
    }

    .desktop-menu > li:not(.mobile-element) {
      display: block;
    }
    .desktop-menu > li.mobile-element {
      display: none;
    }

    .desktop-menu li {
      a:hover,
      button:hover {
        color: var(--primary-500);
      }
    }

    .languages-list {
      margin: 1rem;
      margin-right: 0;
    }
  }
`;

export default Wrapper;
