import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    z-index: 9999;

    svg {
        height: 30px;
        width: 30px;
    }

    .close-btn,
    .flip-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        color: var(--white);
    }

    .close-btn {
        top: 0;
        right: 0;
        padding: 20px;
    }

    .flip-btn {
        left: calc(50% + 100px);
        white-space: nowrap;
        bottom: 38px;
        transform: translateX(-50%);
        padding: 10px;
    }

    .react-html5-camera-photo {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        video,
        img {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
        #outer-circle {
            cursor: pointer;
            height: 52px;
            width: 52px;
            left: -26px;
            #inner-circle {
                height: 60%;
                width: 60%;
                margin: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        #white-flash {
            position: fixed;
            top: 0;
            pointer-events: none;
        }
    }
`;

export default Wrapper;
