import { useState, useEffect } from 'react';
import { marked } from 'marked';
import { HiPlus } from 'react-icons/hi';
import { Modal } from '../../../components';
import { useAppContext } from '../../../context/appContext';
import { postLanguages } from '../../../utils/constants';
import { decodeHtml } from '../../../utils/utils';
import Wrapper from './Post_styles';

const Post = () => {
  const {
    appLoading,
    posts: { categories },
    showAlert,
    getPostsAdmin,
    createPost,
    updatePost,
    editingPostData,
    stopEditPost,
  } = useAppContext();

  const initialValues = {
    title: editingPostData?.title || '',
    subtitle: editingPostData?.subtitle || '',
    category: editingPostData?.category || categories[0] || 'home',
    language: editingPostData?.language || postLanguages[0],
    posterPath: editingPostData?.posterPath || '',
    content: decodeHtml(editingPostData?.content) || '',
  };

  const [values, setValues] = useState(initialValues);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const isEditingPost = editingPostData ? true : false;

  const toggleCategoryModal = e => setIsCategoryModalOpen(!isCategoryModalOpen);

  const addNewCategory = e => {
    e.preventDefault();

    const category = document
      .getElementById('newCategory')
      .value.trim()
      .toLowerCase();

    if (category.length < 3 || category.length > 15) {
      return showAlert({
        alertType: 'danger',
        alertText: 'Category must be between 3 and 15 characters long',
      });
    }

    setNewCategory(category);
    setValues({ ...values, category });
    toggleCategoryModal();
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const submitPost = e => {
    e.preventDefault();

    const { title, subtitle, posterPath, content, category, language } = values;

    if (
      !title ||
      !subtitle ||
      !posterPath ||
      !content ||
      !category ||
      !language
    ) {
      return showAlert({
        alertType: 'danger',
        alertText: 'Please provide all values for post',
      });
    }

    if (isEditingPost) {
      updatePost(editingPostData._id, 'Post updated', values);
    } else {
      createPost(values);
    }
  };

  useEffect(() => {
    categories.length < 1 && getPostsAdmin();
    return () => {
      stopEditPost();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className="dashboard-main">
      <div className="title-container">
        <h3>{isEditingPost ? 'Edit Post' : 'New Post'}</h3>
      </div>
      <form onSubmit={submitPost}>
        <div className="form-row">
          <label className="form-label" htmlFor="title">
            Title
          </label>
          <input
            type="text"
            className="form-input"
            id="title"
            name="title"
            onChange={handleChange}
            value={values.title}
          />
        </div>
        <div className="form-row has-button">
          <label className="form-label" htmlFor="category">
            Category
          </label>
          <div>
            <select
              className="form-select"
              name="category"
              id="category"
              onChange={handleChange}
              value={values.category}
            >
              {categories.map((category, i) => (
                <option key={i} value={category}>
                  {category}
                </option>
              ))}
              {newCategory && (
                <option value={newCategory}>{newCategory}</option>
              )}
            </select>
            <button type="button" className="btn" onClick={toggleCategoryModal}>
              <HiPlus />
            </button>
          </div>
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="language">
            Language
          </label>
          <select
            className="form-select"
            name="language"
            id="language"
            onChange={handleChange}
            value={values.language}
          >
            {postLanguages.map((language, i) => (
              <option key={i} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row full-row">
          <label className="form-label" htmlFor="posterPath">
            Poster URL
          </label>
          <input
            type="text"
            className="form-input"
            id="posterPath"
            name="posterPath"
            onChange={handleChange}
            value={values.posterPath}
          />
        </div>
        <div className="form-row full-row">
          <label className="form-label" htmlFor="subtitle">
            Subtitle
          </label>
          <input
            type="text"
            className="form-input"
            id="subtitle"
            name="subtitle"
            onChange={handleChange}
            value={values.subtitle}
          />
        </div>
        <div className="form-row full-row">
          <label className="form-label" htmlFor="content">
            Markdown Content
          </label>
          <textarea
            className="form-textarea"
            name="content"
            id="content"
            onChange={handleChange}
            value={values.content}
          ></textarea>
        </div>

        <div className="markdown-preview full-row">
          <label className="form-label">Preview</label>
          <div>
            <div className="markdown-container">
              {values.title && <h1 className="post-title">{values.title}</h1>}
              {values.posterPath && (
                <img
                  className="img post-image"
                  src={values.posterPath}
                  alt="Poster"
                />
              )}
              <div className="post-content">
                {values.subtitle && (
                  <p>
                    <strong>{values.subtitle}</strong>
                  </p>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(values.content),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="btn-container full-row">
          <button type="submit" className="btn" disabled={appLoading}>
            Submit
          </button>
        </div>
      </form>

      {isCategoryModalOpen && (
        <Modal onClose={toggleCategoryModal}>
          <form onSubmit={addNewCategory}>
            <div className="form-row">
              <label className="form-label" htmlFor="newCategory">
                Category Name
              </label>
              <input
                type="text"
                className="form-input"
                id="newCategory"
                name="newCategory"
              />
            </div>
            <div className="btn-container">
              <button
                type="button"
                className="btn btn-outline"
                onClick={toggleCategoryModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Add
              </button>
            </div>
          </form>
        </Modal>
      )}
    </Wrapper>
  );
};

export default Post;
