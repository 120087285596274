import styled from 'styled-components';

const Wrapper = styled.article`
  a {
    display: block;
    text-decoration: none;
    font-weight: normal;
    border-radius: var(--borderRadius);
    overflow: hidden;
    box-shadow: var(--shadow-3);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: var(--shadow-1);
    }
  }
  &.featured-post a {
    display: grid;
    grid-template-columns: 1fr;
    min-height: 300px;

    .post-image .img {
      height: 225px !important;
    }
  }

  .post-image {
    position: relative;
    .img {
      height: 175px;
      min-height: 100%;
    }
    span {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      background-color: var(--primary-500);
      color: white;
      text-transform: capitalize;
      border-radius: 1.55rem;
      padding: 0.35rem 0.5rem;
      line-height: 1;
      font-size: var(--extra-small-text);
      box-shadow: var(--shadow-1);
      border: 2px solid var(--white);
    }
  }

  .post-content {
    padding: 1.5rem;
  }

  h1 {
    font-size: 1.55rem;
  }

  p {
    display: block;
    display: -webkit-box;
    max-height: 129px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--grey-400);
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 1.8rem;
    }
  }

  @media screen and (min-width: 992px) {
    &.featured-post a {
      grid-template-columns: 1fr 400px;
    }

    .post-content {
      padding: 2.5rem 2rem;
    }
  }
`;

export default Wrapper;
