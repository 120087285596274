import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/appContext';
import Wrapper from './Login_styles';

const initialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();
  const { user, appLoading, loginUser, showAlert } = useAppContext();

  const [values, setValues] = useState(initialValues);

  const handleValuesChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { password, email } = values;

    if (!email || !password) {
      return showAlert({
        alertType: 'danger',
        alertText: 'Please provide all values',
      });
    }

    const currentUser = { email, password };

    loginUser(currentUser);
  };

  useEffect(() => {
    if (user) {
      user.role === 'admin' && navigate('/admin/');
    }
  }, [user, navigate]);

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>Login</h3>

        <div className="form-row">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="form-input"
            id="email"
            name="email"
            onChange={handleValuesChange}
          />
        </div>

        <div className="form-row">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="form-input"
            id="password"
            name="password"
            onChange={handleValuesChange}
          />
        </div>

        <button type="submit" className="btn btn-block" disabled={appLoading}>
          submit
        </button>
      </form>
    </Wrapper>
  );
};

export default Login;
