import styled from 'styled-components';

const Wrapper = styled.main`
  padding: 3rem 0;
  min-height: 100vh;

  .breadcrumb {
    text-align: center;
    text-transform: capitalize;
    color: var(--grey-400);
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .go-back {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    text-transform: none;

    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5rem 0;
  }
`;

export default Wrapper;
