import styled from 'styled-components';

const Wrapper = styled.main`
    .img {
        max-width: 600px;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .block-center {
        display: block;
        text-align: center;
    }

    .default-radio-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 0.5rem;
        label {
            display: flex;
            align-items: center;
            cursor: pointer;

            input[type='radio'] {
                display: block;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    section {
        margin: 5rem auto;
        max-width: 770px;
        &.full-width-section {
            max-width: none;
        }
        h1 {
            margin-bottom: 2rem;
        }
        h1,
        h3,
        p {
            text-align: center;
        }
        p {
            font-size: 1.25rem;
        }
        .hero-text {
            color: var(--grey-400);
        }
        button {
            display: block;
            margin: 0 auto;
            margin-top: 2rem;
            text-transform: none;
        }
    }

    .form-row {
        margin-bottom: 1rem;

        &.is-double {
            display: grid;
            column-gap: 1.5rem;
            margin-bottom: 0;

            & > div {
                margin-bottom: 1rem;
            }
        }

        &.is-small {
            row-gap: 0.5rem;
            margin-bottom: 1.5rem;
        }

        input[type='checkbox'],
        input[type='radio'] {
            display: none;

            &:checked {
                & + label {
                    background-color: var(--primary-400);
                    color: var(--white);
                }
            }
        }

        .form-radio-label {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1rem;
            border-radius: var(--borderRadius);
            background-color: var(--grey-50);
            letter-spacing: var(--letterSpacing);
            font-weight: bold;
            font-size: 1.05rem;
            border: 4px solid transparent;
        }
    }

    .remove-btn {
        display: flex;
        margin: 0;
        margin-left: auto;
        height: 20px;
        width: 20px;
        svg {
            height: 100%;
            width: 100%;
        }
        &:hover {
            opacity: 0.6;
        }
    }

    .form-row.is-small .form-radio-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 768px) {
        .form-row.is-double {
            grid-template-columns: 1fr 1fr;
        }
        .form-row.is-small {
            min-height: 80px;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

export default Wrapper;
