import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Loading } from '../../components';
import savings from '../../assets/images/savings.svg';
import performance from '../../assets/images/performance.svg';
import { FiInfo } from 'react-icons/fi';
import { CgCloseO } from 'react-icons/cg';
import {
  getFirstOfMonthDateString,
  validateUserInput,
  linearScrollToBottom,
} from '../../utils/utils';
import Wrapper from './Pricing_styles';
import { useAppContext } from '../../context/appContext';

const metaTitle = 'Terzo pilastro - Calcola online il tuo piano di risparmio';
const metaDescription =
  'Personalizza il tuo piano di risparmio grazie al nostro configuratore online. Bastano pochi passi per ricevere le migliori offerte sul mercato in base alle tue esigenze. Vuoi valutare il tuo attuale terzo pilastro? Mandacelo per avere un confronto gratuito.';

const initialValues = {
  newOrExisting: '',
  currentCompany: '',
  currentReward: '',
  genre: '',
  smoker: '',
  frontier: '',
  profession: '',
  born: '',
  height: '',
  weight: '',
  usPerson: '',
  solution: '',
  performance: {
    exempt: 'Esonero dal pagamento dei premi',
    death: '',
    maturity: '',
  },
  contact: 'Si',
  importancy: '',
  insuranceDate: '',
  paymentFrequency: '',
  reward: '',
  customReward: '',
  email: '',
  phone: '',
  fullName: '',
  address: '',
  nationality: '',
  postalCode: '',
  via: '',
  permit: '-',
  remarks: '',
};

const initialErrors = {
  currentCompany: '',
  currentReward: '',
  profession: '',
  born: '',
  height: '',
  weight: '',
  customReward: '',
  email: '',
  phone: '',
  fullName: '',
  nationality: '',
  postalCode: '',
  via: '',
};

const Pricing = () => {
  const { sendPricingForm } = useAppContext();

  const inputsRef = useRef([]);

  const [values, setValues] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const [openInfoBtn, setOpenInfoBtn] = useState('');

  const [errors, setErrors] = useState(initialErrors);
  const [isError, setIsError] = useState(false);

  const [availableInsuranceDates, setAvailableInsuranceDates] = useState([]);

  const getAvailableInsuranceDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const dates = Array.from({ length: 2 }).map((_, i) => {
      return getFirstOfMonthDateString(currentYear, currentMonth + i + 1);
    });

    setAvailableInsuranceDates(dates);
    setValues({ ...values, insuranceDate: dates[0] });
  };

  const clearCustomRewardError = e => {
    setErrors({ ...errors, customReward: '' });
  };

  const handleValuesChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === 'checkbox') {
      const id = e.target.id;
      return setValues({
        ...values,
        [name]: {
          ...values[name],
          [id]: e.target.checked ? e.target.value : '',
        },
      });
    }

    let newValues = { ...values, [name]: value };

    if (name === 'solution' && value === 'Rendimento') {
      newValues.performance.maturity = '';
    }

    setValues(newValues);
  };

  const validateInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    const error = validateUserInput(name, value);

    setErrors({ ...errors, [name]: error });
  };

  const validateAndChangeStep = step => {
    let e = { ...errors };
    let flag = false;
    for (const input of inputsRef.current) {
      if (input && !input.value) {
        flag = true;
        e[input.name] = 'Inserire un valore.';
      }
    }

    if (isError || flag) {
      return setErrors(e);
    }

    changeStep(step);
  };

  const changeStep = step => {
    if (step !== -1 && step <= currentStep) return;

    setCurrentStep(step);
  };

  useEffect(() => {
    if (currentStep === -1) return;
    if (values.insuranceDate && values.paymentFrequency && values.reward) {
      if (values.reward !== 'custom' || values.customReward) {
        return changeStep(8);
      }
    }
    if (values.importancy) return changeStep(7);
    if (values.solution) return changeStep(6);
    if (values.usPerson) return changeStep(5);
    if (values.genre && values.smoker && values.frontier) return changeStep(3);
    if (values.newOrExisting)
      return changeStep(values.newOrExisting === 'new' ? 2 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (currentStep > 0) {
      linearScrollToBottom();
    }
    if (currentStep <= -1) {
      sendPricingForm(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setIsError(
      JSON.stringify(errors) === JSON.stringify(initialErrors) ? false : true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    getAvailableInsuranceDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        {currentStep >= 0 && (
          <section className="new-or-existing">
            <h3>Scegli un opzione</h3>
            <div className="form-row">
              <input
                type="radio"
                id="new"
                name="newOrExisting"
                value="new"
                onChange={(e) => {
                  setErrors({ ...errors, currentCompany: '', currentReward: '' });
                  handleValuesChange(e)
                }}
              />
              <label className="form-radio-label" htmlFor="new">
                Voglio valutare una nuova proposta
              </label>
            </div>
            <div className="form-row">
              <input
                type="radio"
                id="existing"
                name="newOrExisting"
                value="existing"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="existing">
                Voglio confrontare il mio attuale terzo pilastro
              </label>
            </div>
          </section>
        )}
        {currentStep >= 1 && values.newOrExisting === 'existing' && (
          <section>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="currentCompany">
                  Con quale compagnia hai il tuo terzo pilastro?
                </label>
                <input
                  ref={el => (inputsRef.current[0] = el)}
                  type="text"
                  className="form-input"
                  id="currentCompany"
                  name="currentCompany"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.currentCompany}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <label className="form-label" htmlFor="currentReward">
                  Attuale premio annuo
                </label>
                <input
                  ref={el => (inputsRef.current[1] = el)}
                  type="number"
                  className="form-input"
                  id="currentReward"
                  name="currentReward"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.currentReward}</span>
              </div>
            </div>
            {currentStep === 1 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(2)}
              >
                Okay
              </button>
            )}
          </section>
        )}
        {currentStep >= 2 && (
          <section>
            <h3>Raccontaci qualcosa su di te</h3>
            <p>
              Io sono ...
              <button
                className="info-btn"
                type="button"
                onClick={() =>
                  setOpenInfoBtn(openInfoBtn === 'iam' ? '' : 'iam')
                }
              >
                {openInfoBtn === 'iam' ? <CgCloseO /> : <FiInfo />}
              </button>
              {openInfoBtn === 'iam' && (
                <span>
                  Si considera non fumatore qualsiasi persona che non abbia
                  fumato sigarette, o che abbia consumato al massimo due sigari
                  o pipe o altri articoli per fumatori (ad eccezione delle
                  sigarette) al mese durante i dodici mesi precedenti la
                  conclusione del contratto. Le Condizioni Generali di
                  Assicurazione prevedono l'obbligo di dichiarare ogni
                  cambiamento nelle abitudini al fumo.
                  <br />
                  <br />
                  Si considera fumatore qualsiasi persona che abbia fumato
                  sigarette o consumato più di due sigari, pipe o altri articoli
                  da fumo al mese negli ultimi dodici mesi considerati. Le
                  Condizioni Generali di Assicurazione prevedono il diritto di
                  notificare la cessazione del consumo di tabacco.
                  <br />
                  <br />
                  Nell'ambito delle disposizioni contrattuali, le sigarette
                  elettroniche sono assimilate ad altri articoli per fumatori.
                </span>
              )}
            </p>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="man"
                name="genre"
                value="Maschile"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="man">
                Maschile
              </label>
              <input
                type="radio"
                id="woman"
                name="genre"
                value="Femminile"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="woman">
                Femminile
              </label>
            </div>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="smoker"
                name="smoker"
                value="Si"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="smoker">
                Fumatore
              </label>
              <input
                type="radio"
                id="nonSmoker"
                name="smoker"
                value="No"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="nonSmoker">
                Non fumatore
              </label>
            </div>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="resident"
                name="frontier"
                value="Residente"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="resident">
                Residente
              </label>
              <input
                type="radio"
                id="frontier"
                name="frontier"
                value="Frontaliere"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="frontier">
                Frontaliere
              </label>
            </div>
          </section>
        )}
        {currentStep >= 3 && (
          <section>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="profession">
                  Professione
                </label>
                <input
                  ref={el => (inputsRef.current[2] = el)}
                  type="text"
                  className="form-input"
                  id="profession"
                  name="profession"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.profession}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="born">
                  Data di nascita
                </label>
                <input
                  ref={el => (inputsRef.current[3] = el)}
                  type="text"
                  className="form-input"
                  id="born"
                  name="born"
                  placeholder="GG.MM.AAAA"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.born}</span>
              </div>
            </div>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="nationality">
                  Nazionalità
                </label>
                <input
                  ref={el => (inputsRef.current[4] = el)}
                  type="text"
                  className="form-input"
                  id="nationality"
                  name="nationality"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.nationality}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="permit">
                  Tipo di permesso
                </label>
                <select
                  className="form-select"
                  name="permit"
                  id="permit"
                  onChange={handleValuesChange}
                  value={values.permit}
                >
                  <option value="-">-</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="G">G</option>
                </select>
              </div>
            </div>
            {currentStep === 3 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(4)}
              >
                Okay
              </button>
            )}
          </section>
        )}
        {currentStep >= 4 && (
          <section>
            <p>
              Sei una "U.S. person"?
              <button
                className="info-btn"
                type="button"
                onClick={() =>
                  setOpenInfoBtn(openInfoBtn === 'usperson' ? '' : 'usperson')
                }
              >
                {openInfoBtn === 'usperson' ? <CgCloseO /> : <FiInfo />}
              </button>
              {openInfoBtn === 'usperson' && (
                <span>
                  Si definiscono tali tutte le seguenti persone,
                  indipendentemente dal loro effettivo domicilio:
                  <br />
                  <br />
                  - cittadini statunitensi e cittadini statunitensi con doppia
                  nazionalità
                  <br />
                  - possessori di una green card
                  <br />
                  - persone che abbiano soggiornato negli Stati Uniti per
                  almeno:
                  <br />
                  - 31 giorni dell'anno in corso, e<br />
                  - 183 giorni dell'anno in corso e/o dei due anni precedenti. A
                  tal riguardo si conteggiano tutti i giorni dell'anno corrente
                  e 1/3 dei giorni dell'ultimo anno precedente all'anno in corso
                  e 1/6 dei giorni del penultimo anno precedente all'anno in
                  corso.
                  <br />
                  <br />
                  Se si è una «U.S. person» oppure se si è o si diviene un
                  soggetto d'imposta negli Stati Uniti, lo si deve comunicare
                  immediatamente. Ci deve essere comunicata immediatamente anche
                  l'eventuale variazione dello status nel corso della durata
                  contrattuale.
                </span>
              )}
            </p>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="yes-us-person"
                name="usPerson"
                value="Si"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="yes-us-person">
                Si
              </label>
              <input
                type="radio"
                id="no-us-person"
                name="usPerson"
                value="No"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="no-us-person">
                No
              </label>
            </div>
          </section>
        )}
        {currentStep >= 5 && (
          <section>
            <h3>Quale soluzione stai cercando?</h3>
            <div className="form-row is-double">
              <input
                type="radio"
                id="savings"
                name="solution"
                value="Sicurezza"
                onChange={handleValuesChange}
              />
              <label
                className="form-radio-label form-radio-label-img"
                htmlFor="savings"
              >
                <img className="img" src={savings} alt="Sicurezza" />
                <span>Sicurezza</span>
                <small>
                  Ideale per chi desidera mantenere i soldi al sicuro,
                  rinunciando a rendimenti importanti
                </small>
              </label>
              <input
                type="radio"
                id="performance"
                name="solution"
                value="Rendimento"
                onChange={handleValuesChange}
              />
              <label
                className="form-radio-label form-radio-label-img"
                htmlFor="performance"
              >
                <img className="img" src={performance} alt="Rendimento" />
                <span>Rendimento</span>
                <small>
                  Ideale per chi è orientato alla crescita del patrimonio con
                  rendimenti importanti
                </small>
              </label>
            </div>
          </section>
        )}
        {currentStep >= 6 && (
          <>
            <section>
              <h3>Seleziona le prestazioni desiderate</h3>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="exempt"
                  name="performance"
                  disabled
                  value="Esonero dal pagamento dei premi"
                  checked={values.performance['exempt']}
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="exempt"
                >
                  Esonero dal pagamento dei premi
                  {openInfoBtn === 'exempt' && (
                    <span>
                      In caso di malattia o infortunio, l'assicurazione paga il
                      premio al posto tuo. In questo modo hai la sicurezza di
                      ricevere il capitale a scadenza, qualunque cosa accada!
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'exempt' ? '' : 'exempt')
                  }
                >
                  {openInfoBtn === 'exempt' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="death"
                  name="performance"
                  value="Capitale in caso di decesso garantito"
                  checked={values.performance['death']}
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="death"
                >
                  Capitale in caso di decesso garantito
                  {openInfoBtn === 'death' && (
                    <span>
                      Desideri che all'interno del contratto sia assicurato
                      anche un capitale di decesso? In questo modo puoi tutelare
                      meglio la sicurezza per la famiglia.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'death' ? '' : 'death')
                  }
                >
                  {openInfoBtn === 'death' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="maturity"
                  name="performance"
                  value="Garanzia del capitale risparmiato alla scadenza"
                  checked={values.performance['maturity']}
                  onChange={e => {
                    if (values.solution === 'Rendimento') {
                      return toast.error(
                        'Attenzione, la garanzia del capitale è possibile soltanto in combinazione con la soluzione "sicurezza"'
                      );
                    }
                    handleValuesChange(e);
                  }}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="maturity"
                >
                  Garanzia del capitale risparmiato alla scadenza
                  {openInfoBtn === 'maturity' && (
                    <span>
                      Se sei una persona conservativa e orientata alla sicurezza
                      puoi selezionare questa opzione. Una grossa percentuale
                      del tuo risparmio sarà garantita. Questo fa si che i
                      rendimenti del capitale risparmiato si abbassano
                      notevolmente.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'maturity' ? '' : 'maturity')
                  }
                >
                  {openInfoBtn === 'maturity' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
            </section>

            <section>
              <h3>Per te è più importante ...</h3>
              <div className="form-row has-info-btn">
                <input
                  type="radio"
                  id="taxes"
                  name="importancy"
                  value="Ottenere dei vantaggi fiscali, risparmiare sulle imposte"
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="taxes"
                >
                  Ottenere dei vantaggi fiscali, risparmiare sulle imposte
                  (soluzione 3A)
                  {openInfoBtn === 'taxes' && (
                    <span>
                      Scegliendo questa opzione si risparmia mediamente dalle
                      imposte il 20% del premio versato. Esempio pratico:
                      versando un premio di 6883 Fr all'anno si ottiene un
                      risparmio sulle imposte di 1'376 Fr! L'accesso al capitale
                      è però vincolato unicamente ai seguenti motivi: <br />
                      <br /> - Acquisto casa di proprietà <br /> - Trasferimento
                      all'estero <br /> - Avviamento di un'attività indipendente{' '}
                      <br /> - Pre-pensionamento e pensione all'età ordinaria
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'taxes' ? '' : 'taxes')
                  }
                >
                  {openInfoBtn === 'taxes' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="radio"
                  id="capital"
                  name="importancy"
                  value="Possibilità di accesso al capitale per qualsiasi motivo"
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="capital"
                >
                  Possibilità di accesso al capitale per qualsiasi motivo
                  (soluzione 3B)
                  {openInfoBtn === 'capital' && (
                    <span>
                      Scegliendo questa opzione è possibile accedere al capitale
                      per qualsiasi motivo. L'accesso al capitale è possibile in
                      qualsiasi momento, dopo almeno 5 anni di durata.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'capital' ? '' : 'capital')
                  }
                >
                  {openInfoBtn === 'capital' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
            </section>
          </>
        )}
        {currentStep >= 7 && (
          <section>
            <h3>Partenza desiderata?</h3>
            <div className="grid-2">
              <div>
                <label className="form-label" htmlFor="insuranceDate">
                  Assicurato dal
                </label>
                <select
                  className="form-select"
                  name="insuranceDate"
                  id="insuranceDate"
                  onChange={handleValuesChange}
                  value={values.insuranceDate}
                >
                  {availableInsuranceDates.map((date, i) => (
                    <option key={i} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="form-label" htmlFor="paymentFrequency">
                  Frequenza dei pagamenti
                </label>
                <select
                  className="form-select"
                  name="paymentFrequency"
                  id="paymentFrequency"
                  onChange={handleValuesChange}
                  value={values.paymentFrequency}
                >
                  <option value="" disabled>
                    Scegliere
                  </option>
                  <option value="Mensile">Mensile</option>
                  <option value="Trimestrale">Trimestrale</option>
                  <option value="Semestrale">Semestrale</option>
                  <option value="Annuale">Annuale</option>
                </select>
              </div>
              <div>
                <label className="form-label" htmlFor="reward">
                  Premio desiderato (mensile)
                </label>
                <select
                  className="form-select"
                  name="reward"
                  id="reward"
                  onChange={e => {
                    handleValuesChange(e);
                    clearCustomRewardError(e);
                  }}
                  value={values.reward}
                >
                  <option value="" disabled>
                    Scegliere
                  </option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  <option value="300">300</option>
                  <option value="350">350</option>
                  <option value="400">400</option>
                  <option value="450">450</option>
                  <option value="500">500</option>
                  <option value="588">588</option>
                  <option value="custom">Personalizza...</option>
                </select>
              </div>
              {values.reward === 'custom' && (
                <div>
                  <label className="form-label" htmlFor="customReward">
                    Premio desiderato personalizzato
                  </label>
                  <input
                    ref={el => (inputsRef.current[5] = el)}
                    type="number"
                    className="form-input"
                    id="customReward"
                    name="customReward"
                    onChange={handleValuesChange}
                    onBlur={validateInput}
                  />
                  <span className="form-error">{errors.customReward}</span>
                </div>
              )}
            </div>
          </section>
        )}
        {currentStep >= 8 && (
          <section>
            <h3>Ottimo! Ancora poche informazioni</h3>
            <div className="form-row  is-double">
              <div>
                <label className="form-label" htmlFor="fullName">
                  Nome e cognome
                </label>
                <input
                  ref={el => (inputsRef.current[6] = el)}
                  type="text"
                  className="form-input"
                  id="fullName"
                  name="fullName"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.fullName}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="address">
                  Indirizzo completo (via, luogo)
                </label>
                <input
                  type="text"
                  className="form-input"
                  id="address"
                  name="address"
                  onChange={handleValuesChange}
                />
              </div>
            </div>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="email">
                  E-mail
                </label>
                <input
                  ref={el => (inputsRef.current[7] = el)}
                  type="email"
                  className="form-input"
                  id="email"
                  name="email"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.email}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="phone">
                  Telefono
                </label>
                <input
                  ref={el => (inputsRef.current[8] = el)}
                  type="tel"
                  className="form-input"
                  id="phone"
                  name="phone"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.phone}</span>
              </div>
            </div>
            <div className="form-row">
              <label className="default-checkbox" htmlFor="contact-me">
                <input
                  type="radio"
                  id="contact-me"
                  name="contact"
                  value="Si"
                  checked={values.contact === 'Si'}
                  onChange={handleValuesChange}
                />
                Voglio valutare le proposte con un esperto. Contattatemi telefonicamente.
              </label>
            </div>
            <div className="form-row">
              <label className="default-checkbox" htmlFor="dont-contact-me">
                <input
                  type="radio"
                  id="dont-contact-me"
                  name="contact"
                  value="No"
                  checked={values.contact === 'No'}
                  onChange={handleValuesChange}
                />
                Sono in grado di valutare individualmente le proposte. Non voglio essere contattato.
              </label>
            </div>
            <div className="form-row">
              <label className="form-label" htmlFor="remarks">
                Osservazioni
              </label>
              <textarea
                className="form-textarea"
                name="remarks"
                id="remarks"
                rows="5"
                onChange={handleValuesChange}
              ></textarea>
            </div>
            {currentStep === 8 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(-1)}
                style={{
                  textTransform: 'none',
                  background: '#1CC691',
                  borderColor: '#1CC691',
                }}
              >
                Scopri la soluzione migliore
              </button>
            )}
          </section>
        )}
        {currentStep === -1 && (
          <section className="full-width-section">
            <Loading center />
          </section>
        )}
      </div>
    </Wrapper>
  );
};

export default Pricing;
