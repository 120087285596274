import { NavLink } from 'react-router-dom';
import { MdInsertDriveFile, MdPermMedia } from 'react-icons/md';
// import { Logo } from '.';
import { useAppContext } from '../../../context/appContext';
import Wrapper from './AdminSidebar_styles';

const AdminSidebar = () => {
  const {
    showDashboardSidebar,
    toggleDashboardSidebar,
    closeDashboardSidebar,
  } = useAppContext();

  return (
    <Wrapper className={showDashboardSidebar ? 'active' : ''}>
      <div className={showDashboardSidebar ? 'sidebar active' : 'sidebar'}>
        <ul>
          <li className="list-header">Blog</li>
          <li>
            <NavLink to="/admin/posts" onClick={closeDashboardSidebar}>
              <MdInsertDriveFile /> Posts
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/media" onClick={closeDashboardSidebar}>
              <MdPermMedia /> Media
            </NavLink>
          </li>
        </ul>
      </div>
      {showDashboardSidebar && (
        <div className="overlay" onClick={toggleDashboardSidebar}></div>
      )}
    </Wrapper>
  );
};

export default AdminSidebar;
