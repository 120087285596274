import styled from 'styled-components';

const Wrapper = styled.main`
  .files-container {
    display: flex;
    flex-wrap: wrap;
  }

  .search-container {
    margin-bottom: 4rem;
  }
`;

export default Wrapper;
