export const getFirstOfMonthDateString = (year, month) => {
  return new Intl.DateTimeFormat('it-IT')
    .format(new Date(year, month, 1))
    .replaceAll('/', '.');
};

export const validateUserInput = (name, value) => {
  let error = '';

  if (name === 'born') {
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      error = 'Controlla il formato.';
    } else {
      const [day, month, year] = value.split('.');
      const date = new Date(`${year}/${month}/${day}`);

      if (
        !(date instanceof Date) ||
        isNaN(date) ||
        date.getDate() !== Number(day) ||
        date.getMonth() + 1 !== Number(month) ||
        date.getFullYear() !== Number(year) ||
        Number(year) < 1902
      ) {
        error = 'Data non valida';
      }

      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

      if (date > eighteenYearsAgo) {
        error = 'Devi avere almeno 18 anni.';
      }
    }
  }
  if (name === 'height') {
    if (!/^\d+$/.test(value)) {
      error = 'Inserire un numero.';
    } else {
      if (Number(value) < 80) {
        error = 'Valore deve essere superiore o uguale a 80.';
      }
      if (Number(value) > 240) {
        error = 'Valore deve essere inferiore o uguale a 240.';
      }
    }
  }
  if (name === 'weight') {
    if (!/^\d+$/.test(value)) {
      error = 'Inserire un numero.';
    } else {
      if (Number(value) < 30) {
        error = 'Valore deve essere superiore o uguale a 30.';
      }
      if (Number(value) > 300) {
        error = 'Valore deve essere inferiore o uguale a 300.';
      }
    }
  }
  if (name === 'email') {
    if (!/\b[\w.-]+@[\w.-]+\.\w{2,4}\b/i.test(value)) {
      error = 'Inserire un indirizzo e-mail valido.';
    }
  }
  if (name === 'phone') {
    const tempValue = value.replace(/[()-\s.]/g, '');
    if (
      !/^\+([0-9]{1,3})([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue) &&
      !/^([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue) &&
      !/^0{2}([0-9]{1,3})([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue)
    ) {
      error = 'Telefono non valido.';
    }
  }
  if (name === 'fullName') {
    if (value.replaceAll(' ', '').length < 4) {
      error = 'Inserire almeno 4 lettere.';
    }
  }
  if (
    name === 'profession' ||
    name === 'nationality' ||
    name === 'postalCode'
  ) {
    if (value.trim().length < 4) {
      error = 'Inserire almeno 4 lettere.';
    }
  }
  if (
    name === 'customReward' ||
    name === 'customReward2' ||
    name === 'currentReward'
  ) {
    if (!/^\d+$/.test(value)) {
      error = 'Inserire un numero.';
    }
  }
  if (name === 'via' || name === 'currentCompany') {
    if (!value) {
      error = 'Inserire un valore.';
    }
  }

  return error;
};

export const linearScrollToBottom = () => {
  const topScroll = document.documentElement.scrollTop;
  const bottomScroll = Math.ceil(topScroll + window.innerHeight);
  const marginOfError = 2;

  if (bottomScroll + marginOfError >= document.documentElement.offsetHeight)
    return;

  let scrollY = topScroll;
  let oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      scrollY += 5;
      if (scrollY >= document.documentElement.offsetHeight - window.innerHeight)
        return window.scrollTo({
          top: document.documentElement.offsetHeight,
        });
      window.scrollTo({ top: scrollY });
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
};

export const getResourceType = fileName => {
  const imagePattern = /\.(gif|jpe?g|png|webp)$/i;
  const videoPattern = /\.(mp4|mov)$/i;

  if (imagePattern.test(fileName)) {
    return 'image';
  }
  if (videoPattern.test(fileName)) {
    return 'video';
  }

  return null;
};

export const getFileExtension = fileName => {
  return (
    fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
    fileName
  );
};

export const formatTimestamp = timestamp => {
  return new Intl.DateTimeFormat('it-IT')
    .format(new Date(timestamp))
    .replaceAll('/', '.');
};

export const formatTimestampToFullDate = timestamp => {
  return new Intl.DateTimeFormat('it-IT', {
    dateStyle: 'full',
  }).format(new Date(timestamp));
};

export const decodeHtml = html => {
  if (!html) return null;

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};
