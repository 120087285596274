import { RiGlobalLine } from 'react-icons/ri';
import { BiCategory } from 'react-icons/bi';
import { HiStatusOnline } from 'react-icons/hi';
import { IoEllipsisVertical } from 'react-icons/io5';
import { IoMdTrash } from 'react-icons/io';
import { MdOutlineCalendarToday, MdStar, MdEdit } from 'react-icons/md';
import { useAppContext } from '../../../context/appContext';
import { formatTimestamp } from '../../../utils/utils';
import Wrapper from './Post_styles';

const Post = ({
  _id,
  title,
  language,
  category,
  posterPath,
  isFeatured,
  isPublished,
  createdAt,
  openMenuItem,
  setOpenMenuItem,
}) => {
  const { deletePost, updatePost, startEditPost } = useAppContext();

  const creationDate = formatTimestamp(createdAt);

  const toggleMenu = e => {
    openMenuItem === _id ? setOpenMenuItem(null) : setOpenMenuItem(_id);
  };

  const editPost = e => {
    startEditPost(_id);
  };

  const togglePublishPost = e => {
    updatePost(_id, 'Post publish status updated', {
      isPublished: !isPublished,
    });
    toggleMenu();
  };

  const toggleFeaturePost = e => {
    updatePost(_id, 'Post feature status updated', {
      isFeatured: !isFeatured,
    });
    toggleMenu();
  };

  const deleteCurrentPost = e => {
    deletePost(_id);
    toggleMenu();
  };

  return (
    <Wrapper>
      <div className="poster-container">
        <img className="img" src={posterPath} alt="Poster" />
        <div className="poster-footer">
          <MdStar className={isFeatured ? 'feature is-featured' : 'feature'} />
          <span
            className={isPublished ? 'status is-published' : 'status'}
          ></span>
        </div>
      </div>
      <div className="post-title">
        <span>{title}</span>
      </div>
      <div className="post-info">
        <span>
          <RiGlobalLine />
          {language}
        </span>
        <span className="category">
          <BiCategory />
          {category}
        </span>
        <span>
          <MdOutlineCalendarToday />
          {creationDate}
        </span>
      </div>

      <div className="menu">
        <button type="button" onClick={toggleMenu}>
          <IoEllipsisVertical />
        </button>

        <ul className={openMenuItem === _id ? 'active' : ''}>
          <li>
            <button type="button" onClick={togglePublishPost}>
              <HiStatusOnline />
              <span>{isPublished ? 'Unpublish' : 'Publish'}</span>
            </button>
          </li>
          <li>
            <button type="button" onClick={toggleFeaturePost}>
              <MdStar />
              <span>{isFeatured ? 'Stop featuring' : 'Set as featured'}</span>
            </button>
          </li>
          <li>
            <hr />
          </li>
          <li>
            <button type="button" onClick={editPost}>
              <MdEdit />
              <span>Edit</span>
            </button>
          </li>
          <li>
            <hr />
          </li>
          <li>
            <button type="button" onClick={deleteCurrentPost}>
              <IoMdTrash />
              <span>Delete</span>
            </button>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default Post;
