import styled from 'styled-components';

const Wrapper = styled.main`
  section {
    margin: 5rem auto;
    h3,
    p {
      text-align: center;
    }
    p {
      font-size: 1.25rem;
    }
  }

  .img {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 5rem;
  }
`;

export default Wrapper;
