import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/appContext';
import { postLanguages } from '../../../utils/constants';
import { Loading, Post, PaginationContainer } from '../../../components';
import Wrapper from './AllPosts_styles';

const AllPosts = () => {
  const {
    posts: { categories, data: posts, pages, currentPage, query },
    changePage,
    handleQueryChange,
    clearQueryAndPage,
    appLoading,
    getPostsAdmin,
  } = useAppContext();

  const [openMenuItem, setOpenMenuItem] = useState(null);

  const submitQuery = e => {
    e.preventDefault();

    let newQuery = {};

    Object.keys(query).forEach(id => {
      const input = document.getElementById(id);
      newQuery[input.name] = input.value;
    });

    handleQueryChange('posts', newQuery);
    setOpenMenuItem(null);
  };

  useEffect(() => {
    getPostsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentPage]);

  useEffect(() => {
    return () => clearQueryAndPage('posts');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className="dashboard-main">
      <div className="title-container">
        <h3>Blog Posts</h3>
        <Link to="post" className="btn">
          New
        </Link>
      </div>

      <form className="search-container" onSubmit={submitQuery}>
        <div className="form-row">
          <label className="form-label" htmlFor="search">
            Search
          </label>
          <input
            type="search"
            className="form-input"
            id="search"
            name="search"
          />
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="category">
            Category
          </label>
          <select className="form-select" name="category" id="category">
            <option value="all">All</option>
            {categories.map((category, i) => (
              <option key={i} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="language">
            Language
          </label>
          <select className="form-select" name="language" id="language">
            <option value="all">All</option>
            {postLanguages.map((language, i) => (
              <option key={i} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="published">
            Published Status
          </label>
          <select className="form-select" name="published" id="published">
            <option value="all">All</option>
            <option value="published">Published</option>
            <option value="unpublished">Unpublished</option>
          </select>
        </div>
        <div className="form-row">
          <label className="form-label" htmlFor="featured">
            Featured Status
          </label>
          <select className="form-select" name="featured" id="featured">
            <option value="all">All</option>
            <option value="featured">Featured</option>
            <option value="unfeatured">Unfeatured</option>
          </select>
        </div>
        <button type="submit" className="btn" disabled={appLoading}>
          Find
        </button>
      </form>

      {appLoading ? (
        <Loading center />
      ) : (
        <>
          <div className="posts-container">
            {posts?.map(post => (
              <Post
                key={post._id}
                {...post}
                openMenuItem={openMenuItem}
                setOpenMenuItem={setOpenMenuItem}
              />
            ))}
          </div>
          {pages > 1 && (
            <PaginationContainer
              numOfPages={pages}
              currentPage={currentPage}
              handleChange={changePage}
              objectType="posts"
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default AllPosts;
