import styled from 'styled-components';

const Wrapper = styled.section`
  min-height: 100vh;
  display: grid;
  align-items: center;
  background-color: var(--grey-50);

  .form {
    width: 90vw;
    max-width: 400px;
    padding: 2rem 2.5rem;
    box-shadow: var(--shadow-2);
    border-top: 5px solid var(--primary-500);

    .form-row {
      margin-bottom: 1rem;
    }
  }

  h3 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 2rem;
  }

  .btn {
    margin-top: 1rem;
  }
`;

export default Wrapper;
