import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Wrapper from './Home_styles';
import {
    AiOutlineCalculator,
    AiOutlineDesktop,
    AiOutlineMail,
} from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail, HiOutlineCalendar } from 'react-icons/hi';
import supportImg from '../../assets/images/support.png';
import techImg from '../../assets/images/tech.svg';
import chatImg from '../../assets/images/chat.svg';
import superWomanImg from '../../assets/images/super-woman.svg';
import businessImg from '../../assets/images/business.svg';

const metaTitle = 'Terzo pilastro - la previdenza privata su misura per te';
const metaDescription =
    "Il terzo pilastro è un piano di risparmio per la pensione che permette di investire il proprio denaro senza rinunciare alle coperture assicurative tra cui la rendita di invalidità, il capitale in caso di decesso, l'esonero dal pagamento dei premi in caso di malattia e infortunio. Fai la tua richiesta ora: su misura per le tue esigenze.";

const fusionText = ['Facile', 'Pratico', '100% online'];

const Home = () => {
    const fusionTextRef = useRef(null);
    const [currentFusionTextIndex, setCurrentFusionTextIndex] = useState(0);
    const [effectTriggerer, setEffectTriggerer] = useState(Date.now());

    useEffect(() => {
        const el = fusionTextRef.current;
        let timeout;
        if (el.classList.contains('shrink')) {
            el.classList.remove('shrink');
            timeout = setTimeout(() => {
                setEffectTriggerer(Date.now());
            }, 3000);
        } else {
            el.classList.add('shrink');
            timeout = setTimeout(() => {
                const newIndex =
                    currentFusionTextIndex >= fusionText.length - 1
                        ? 0
                        : currentFusionTextIndex + 1;

                setCurrentFusionTextIndex(newIndex);
            }, 1000);
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFusionTextIndex, effectTriggerer]);

    return (
        <Wrapper>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
            </Helmet>
            <div className="container">
                <section className="hero-section">
                    <div className="content">
                        <h1>Personalizza il tuo terzo pilastro</h1>
                        <h2 style={{ fontWeight: 'normal' }}>
                            senza troppe chiacchiere
                        </h2>
                        <p className="hero-text fusion-text">
                            <span ref={fusionTextRef}>
                                {fusionText[currentFusionTextIndex]}
                            </span>
                        </p>
                        <Link className="btn btn-hero btn-block" to="/pricing">
                            configuratore online
                        </Link>
                        <Link
                            className="btn btn-outline btn-hero btn-block"
                            to="/contact"
                            style={{ textTransform: 'none' }}
                        >
                            Chiedi una Consulenza
                        </Link>
                    </div>
                    <div className="image">
                        <img src={supportImg} className="img" alt="Support" />
                    </div>
                </section>
                <hr />
                <section>
                    <div className="content">
                        <h2>Fatto su misura</h2>
                        <p>
                            Sapevi che in Svizzera esistono oltre 240 banche e
                            circa 200 istituti assicurativi? Terzopilastro.info
                            analizza le tue richieste e ti guida verso la
                            migliore soluzione sul mercato.
                        </p>
                    </div>
                    <div className="image">
                        <img src={techImg} className="img" alt="Technology" />
                    </div>
                </section>
                <section className="reverse-section">
                    <div className="content">
                        <h2>100% online</h2>
                        <p>
                            Dalla ricerca alla stipula! Quanto è comodo non
                            doversi scomodare da casa? Senza prendere un
                            appuntamento, senza rispettare gli orari d'ufficio.
                        </p>
                    </div>
                    <div className="image">
                        <img src={chatImg} className="img" alt="Chatting" />
                    </div>
                </section>
                <section>
                    <div className="content">
                        <h2>Super moderno</h2>
                        <p>
                            Scegli il tuo canale preferito: telefono, e-mail,
                            oppure scrivici via WhatsApp. Trovi anche la
                            video-consulenza online con gli specialisti della
                            previdenza!
                        </p>
                    </div>
                    <div className="image">
                        <img
                            src={superWomanImg}
                            className="img"
                            alt="Super Woman"
                        />
                    </div>
                </section>
                <section className="reverse-section">
                    <div className="content">
                        <h2>Self Academy</h2>
                        <p>
                            Vuoi scoprire di più ma non vuoi discutere con gli
                            agenti assicurativi? Lo sappiamo! Per questo abbiamo
                            creato una sezione dedicata{' '}
                            <Link
                                to="/info"
                                style={{ color: 'var(--primary-500)' }}
                            >
                                (info)
                            </Link>{' '}
                            dove troverai tutte le informazioni importanti sulla
                            previdenza e sul terzo pilastro.
                        </p>
                    </div>
                    <div className="image">
                        <img
                            src={businessImg}
                            className="img"
                            alt="Business"
                            loading="lazy"
                            style={{ maxHeight: 400, objectFit: 'fill' }}
                        />
                    </div>
                </section>
            </div>
            <div className="banner banner-data">
                <div className="container">
                    <div className="item">
                        <p className="data">7'300</p>
                        <small>Clienti</small>
                    </div>
                    <div className="item">
                        <p className="data">8'900</p>
                        <small>Soluzioni consigliate</small>
                    </div>
                    <div className="item">
                        <p className="data">4.9/5</p>
                        <small>Valutazione Google</small>
                    </div>
                    <div className="item">
                        <p className="data">2018</p>
                        <small>Anno di fondazione</small>
                    </div>
                </div>
            </div>
            <div className="container">
                <section className="its-simple">
                    <h1>Come funziona?</h1>
                    <div>
                        <div className="item">
                            <AiOutlineCalculator />
                            <p>
                                Personalizza e configura la tua richiesta
                                online.
                            </p>
                        </div>
                        <div className="item">
                            <AiOutlineMail />
                            <p>
                                Ricevi le migliori offerte per e-mail entro 48h.
                            </p>
                        </div>
                        <div className="item">
                            <AiOutlineDesktop />
                            <p>Scegli e procedi con la stipula online.</p>
                        </div>
                    </div>
                    <Link className="btn btn-hero" to="/pricing">
                        configuratore online
                    </Link>
                </section>
                <hr />
                <section className="need-help">
                    <h3>Possiamo aiutarti?</h3>
                    <div>
                        <div className="item">
                            <p>Scrivici una mail</p>
                            <a
                                aria-label="Scrivici una mail"
                                href="mailto:info@elefin.ch"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <HiOutlineMail />
                            </a>
                        </div>
                        <div className="item">
                            <p>Chatta con noi su WhatsApp</p>
                            <a
                                aria-label="Chatta con noi"
                                href="https://wa.me/+41918808787"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <FaWhatsapp />
                            </a>
                        </div>
                        <div className="item">
                            <p>Prenota un appuntamento</p>
                            <a
                                aria-label="Prenota un appuntamento"
                                href="https://calendly.com/terzopilastro"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <HiOutlineCalendar />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    );
};

export default Home;
