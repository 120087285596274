import { Helmet } from 'react-helmet';
import finishLine from '../../assets/images/finish-line.svg';
import Wrapper from './ThankYou_styles';

const metaTitle = 'Terzo pilastro - Thank You';

const ThankYou = () => {
  return (
    <Wrapper className="container">
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="full-width-section">
        <h3>Grazie per aver scelto terzopilastro.info!</h3>
        <p>
          Entro 48h riceverai per e-mail le migliori soluzioni sul mercato
          selezionate per te!
        </p>
        <img className="img" src={finishLine} alt="Finish Line" />
      </section>
    </Wrapper>
  );
};
export default ThankYou;
