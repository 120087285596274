import styled from 'styled-components';

const Wrapper = styled.main`
  .posts-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .search-container {
    margin-bottom: 4rem;
  }

  select[name='category'] {
    text-transform: capitalize;
  }
`;

export default Wrapper;
