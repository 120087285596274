import styled from 'styled-components';

const Wrapper = styled.article`
  position: relative;
  border-radius: var(--borderRadius);
  width: 100%;
  max-width: 235px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  line-height: 1;
  font-weight: bold;
  font-size: var(--small-text);
  box-shadow: var(--shadow-1);

  .img {
    height: 158px;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .media-name {
    padding: 0.75rem 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--grey-100);
  }

  .media-info {
    color: var(--primary-500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    font-size: var(--extra-small-text);

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.25rem;

      svg {
        margin-right: 0.25rem;
      }
    }

    button {
      display: flex;
      padding: 0 0.25rem;
    }

    svg {
      font-size: 1rem;
    }
  }
`;

export default Wrapper;
