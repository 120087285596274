import styled from 'styled-components';

const Wrapper = styled.main`
    .colored {
        color: var(--primary-500);
    }
    .list-container {
        display: flex;
        justify-content: center;
    }
    .center-btn {
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
    }
    section {
        max-width: 770px;
        margin: 5rem auto;
        h3,
        p {
            text-align: center;
        }
        p {
            font-size: 1.25rem;
        }
        ul {
            list-style-type: disc;
            padding-left: 30px;
        }
    }

    .img {
        max-width: 600px;
        margin: 0 auto;
        margin-top: 5rem;
    }
`;

export default Wrapper;
