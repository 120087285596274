import styled from 'styled-components';

const Wrapper = styled.footer`
  padding: 4rem 0;
  color: var(--grey-400);
  background-color: var(--grey-50);
  font-size: 1.25rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;

    li {
      min-width: 160px;
      padding: 0.75rem 0;
      padding-right: 1rem;
      p {
        cursor: default;
        font-weight: 600;
        margin: 0;
      }
    }

    .social-icons {
      display: flex;
      font-size: 1.5rem;
      a {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-right: 0.75rem;
        }
      }
    }
  }

  .languages-list {
    display: inline-block;
    position: relative;
    font-size: 1rem;

    button {
      display: flex;
      align-items: center;
      svg:first-of-type {
        font-size: 1.25rem;
      }

      span {
        font-weight: 600;
        padding-left: 0.25rem;
        padding-right: 0.15rem;
      }
    }
    ul {
      display: none;
      position: absolute;
      left: 0;
      bottom: calc(100% + 0.5rem);
      width: 100%;
      text-align: center;
      padding: 0.25rem;
      background-color: var(--white);
      border-radius: var(--borderRadius);
      border: 1px solid var(--grey-100);
      box-shadow: var(--shadow-3);
      z-index: 2;

      li a {
        display: block;
        padding: 0.25rem;
      }
    }

    &.is-open {
      button {
        color: var(--primary-500);
      }
      ul {
        display: block;
      }
    }
  }

  hr {
    margin: 2rem 0;
  }

  .copyright-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 992px) {
    .footer-links a:hover,
    .languages-list a:hover,
    .languages-list button:hover {
      color: var(--primary-500);
    }
  }
`;

export default Wrapper;
