import { saveAs } from 'file-saver';
import { IoEllipsisVertical } from 'react-icons/io5';
import { IoMdTrash } from 'react-icons/io';
import { AiOutlineLink } from 'react-icons/ai';
import { MdImage, MdVideocam, MdFileDownload } from 'react-icons/md';
import { getResourceType, getFileExtension } from '../../../utils/utils';
import Wrapper from './Media_styles';
import { useAppContext } from '../../../context/appContext';

const Media = ({ _id, name, resourcePath, openMenuItem, setOpenMenuItem }) => {
  const { showAlert, deleteMediaFile } = useAppContext();

  const resourceType = getResourceType(resourcePath);
  const extension = getFileExtension(name).toUpperCase();

  const toggleMenu = e => {
    openMenuItem === _id ? setOpenMenuItem(null) : setOpenMenuItem(_id);
  };

  const copyToClipboard = e => {
    navigator.clipboard.writeText(resourcePath);
    showAlert({
      alertType: 'info',
      alertText: 'Link copied!',
    });
  };

  const downloadFile = e => {
    saveAs(resourcePath, name);
  };

  const deleteFile = e => {
    deleteMediaFile(_id);
  };

  return (
    <Wrapper>
      {resourceType === 'image' && (
        <img className="img" src={resourcePath} alt={name} />
      )}
      {resourceType === 'video' && (
        <video className="img" src={resourcePath} controls></video>
      )}
      <div className="media-name">
        <span>{name}</span>
      </div>
      <div className="media-info">
        <span>
          {resourceType === 'image' && <MdImage />}
          {resourceType === 'video' && <MdVideocam />}
          {extension}
        </span>
        <button type="button" onClick={copyToClipboard}>
          <AiOutlineLink />
        </button>
      </div>

      <div className="menu">
        <button type="button" onClick={toggleMenu}>
          <IoEllipsisVertical />
        </button>

        <ul className={openMenuItem === _id ? 'active' : ''}>
          <li>
            <button type="button" onClick={copyToClipboard}>
              <AiOutlineLink />
              <span>Copy link</span>
            </button>
          </li>
          <li>
            <button type="button" onClick={downloadFile}>
              <MdFileDownload />
              <span>Download</span>
            </button>
          </li>
          <li>
            <hr />
          </li>
          <li>
            <button type="button" onClick={deleteFile}>
              <IoMdTrash />
              <span>Delete</span>
            </button>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default Media;
