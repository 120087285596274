import {
  BEGIN_REQUEST,
  FINISH_REQUEST,
  REQUEST_ERROR,
  DISPLAY_ALERT,
  CLEAR_ALERT,
  GET_PUBLIC_POSTS_SUCCESS,
  GET_SINGLE_PUBLIC_POST_SUCCESS,
  TOGGLE_DASHBOARD_SIDEBAR,
  CLOSE_DASHBOARD_SIDEBAR,
  LOGOUT_USER,
  LOGIN_USER_SUCCESS,
  CHANGE_PAGE,
  HANDLE_QUERY_CHANGE,
  CLEAR_QUERY_AND_PAGE,
  GET_MEDIA_FILES_SUCCESS,
  GET_POSTS_ADMIN_SUCCESS,
  START_EDIT_POST,
  STOP_EDIT_POST,
} from './actions';
import { initialState } from './appContext';

const reducer = (state, action) => {
  if (action.type === BEGIN_REQUEST) {
    return { ...state, appLoading: true };
  }
  if (action.type === FINISH_REQUEST) {
    return {
      ...state,
      appLoading: false,
      showAlert: action.payload.showAlert || state.showAlert,
      alertType: action.payload.alertType || state.alertType,
      alertText: action.payload.alertText || state.alertText,
    };
  }
  if (action.type === REQUEST_ERROR) {
    return {
      ...state,
      appLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.message,
    };
  }
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: action.payload.alertType,
      alertText: action.payload.alertText,
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    };
  }
  if (action.type === GET_PUBLIC_POSTS_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      posts: {
        ...state.posts,
        categories: action.payload.categories,
        featuredPost: action.payload.featuredPost,
        data: action.payload.posts,
        pages: action.payload.numOfPages,
      },
    };
  }
  if (action.type === GET_SINGLE_PUBLIC_POST_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      singlePost: action.payload.post,
    };
  }
  if (action.type === TOGGLE_DASHBOARD_SIDEBAR) {
    return {
      ...state,
      showDashboardSidebar: !state.showDashboardSidebar,
    };
  }
  if (action.type === CLOSE_DASHBOARD_SIDEBAR) {
    return {
      ...state,
      showDashboardSidebar: false,
    };
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
    };
  }
  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      showAlert: true,
      alertType: 'success',
      alertText: 'Welcome back!',
    };
  }
  if (action.type === CHANGE_PAGE) {
    return {
      ...state,
      [action.payload.objectType]: {
        ...state[action.payload.objectType],
        currentPage: action.payload.page,
      },
    };
  }
  if (action.type === HANDLE_QUERY_CHANGE) {
    return {
      ...state,
      [action.payload.objectType]: {
        ...state[action.payload.objectType],
        currentPage: 1,
        query: action.payload.query,
      },
    };
  }
  if (action.type === CLEAR_QUERY_AND_PAGE) {
    let returnObj = {
      ...state,
      [action.payload.objectType]: {
        ...initialState[action.payload.objectType],
      },
    };
    if (action.payload.objectType === 'posts') {
      returnObj.posts.categories = state.posts.categories;
    }
    return returnObj;
  }
  if (action.type === GET_MEDIA_FILES_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      mediaFiles: {
        ...state.mediaFiles,
        data: action.payload.files,
        pages: action.payload.numOfPages,
      },
    };
  }
  if (action.type === GET_POSTS_ADMIN_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      posts: {
        ...state.posts,
        categories: action.payload.categories,
        data: action.payload.posts,
        pages: action.payload.numOfPages,
      },
    };
  }
  if (action.type === START_EDIT_POST) {
    return {
      ...state,
      editingPostData: action.payload.post,
    };
  }
  if (action.type === STOP_EDIT_POST) {
    return {
      ...state,
      editingPostData: null,
    };
  }
  throw new Error(`No such action: ${action.type}`);
};

export default reducer;
