import styled from 'styled-components';

const Wrapper = styled.main`
  color: var(--grey-400);

  section {
    padding: 3rem 0;
  }

  .hero-section {
    padding-bottom: 0;
    .hero-text {
      margin-bottom: 0;
    }
    .img {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .accordion-container {
    max-width: 800px;
    margin: 0 auto;

    .item {
      border-bottom: 1px solid var(--grey-100);
      & > button {
        display: flex;
        width: 100%;
        padding: 2rem 0;
        text-align: left;

        h5 {
          flex: 1;
          margin-top: 0.2rem;
          margin-bottom: 0;
        }

        svg {
          color: var(--textColor);
          margin-right: 0.75rem;
          font-size: 1.75rem;
        }
      }

      .accordion {
        max-height: 0;
        overflow: hidden;

        & > div {
          padding: 1rem 0 3rem 0;
          padding-left: 2.5rem;
          opacity: 0;
          transform: translateY(-10px);
          transition: all 0.4s ease-out;

          p {
            margin: 0;
          }
        }

        &.open {
          max-height: 10000px;

          div {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }

  .btn-container {
    margin-top: 2.5rem;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .hero-section {
      text-align: center;
    }
    p {
      font-size: 1.15rem;
    }
    section {
      padding: 5rem 0;
    }
    .accordion-container .item {
      & > button svg {
        font-size: 2rem;
      }
      .accordion > div {
        padding-left: 2.75rem;
      }
    }
  }
`;

export default Wrapper;
