import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { faqItems } from '../../utils/constants';
import Wrapper from './Faq_styles';

const metaTitle = 'Terzo pilastro - le domande più frequenti che puoi farti';
const metaDescription =
  'Hai ancora dei dubbi su quale piano scegliere? Qui trovi le risposte alle domande più frequenti che abbiamo riscontrato parlando con i nostri clienti. Ma ricordati, puoi contattarci in ogni momento per parlare con noi ed appianare ogni perplessità.';

const Contact = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        <section className="hero-section">
          <h1>FAQ</h1>
          <p className="hero-text">
            Qui trovi le risposte alle domande più frequenti.
          </p>
        </section>

        <section>
          <div className="accordion-container">
            {faqItems.map(({ id, title, html }) => (
              <div key={id} className="item">
                <button
                  type="button"
                  onClick={() =>
                    setOpenAccordion(openAccordion === id ? null : id)
                  }
                >
                  {openAccordion === id ? <BiMinusCircle /> : <BiPlusCircle />}
                  <h5>{title}</h5>
                </button>
                <div
                  className={
                    openAccordion === id ? 'accordion open' : 'accordion'
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
              </div>
            ))}
          </div>

          <div className="btn-container">
            <Link
              className="btn btn-outline btn-hero"
              to="/contact"
              style={{ textTransform: 'none' }}
            >
              Non hai trovato risposta?
            </Link>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Contact;
